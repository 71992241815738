@import "../../abstracts/_variables.scss";
@import "../../abstracts/_breakpoints.scss";

.category {
    grid-template-rows: max-content 0fr;
    transition: grid-template-rows $animation_transition;

    &.expanded {
        grid-template-rows: max-content 1fr;
    }

    .articles {
        margin-inline: -.5rem;
        padding-inline: .5rem;
    }

    > .d-flex {
        border-bottom: 1px solid $text_color_dark;

        .bi::before {
            font-weight: 600 !important;
        }
    }
}


.article {
    gap: .2rem 1rem;   

    &.has-image {
        
        grid-template-columns: max-content 1fr;

        .article-buttons {            
            grid-column: span 2;
        }
    }
 
    .article-info {
        gap: .2rem 1rem;   
    }

    .article-buttons {
        margin-top: 1rem;
        grid-template-columns: 1fr 1fr;

        .btn-primary {
            grid-column: 2;
        }

        .btn-linkalike {
            grid-row: 1;
        }
    }

    picture {
        grid-column: 1;
        grid-row: 1 / 3;
    }


    @include media-breakpoint-only(sm) {
        gap: .2rem 2rem;
         
        h4 {
            grid-column: span 2;
        }

        .article-buttons {
            grid-column: 2;
            grid-row: 2;
            grid-template-columns: max-content;
            place-content: center end;
            margin-top: 0;
        }

        &.has-image {
        
            grid-template-columns: max-content 1fr max-content;

            .article-buttons {            
                grid-column: 3;
            }
        }

        &.has-description .article-buttons {
            grid-template-columns: max-content max-content;
        }

    }

    @include media-breakpoint-up(lg) {
        gap: .2rem 2rem;
         
        h4 {
            grid-column: span 2;
        }

        .article-buttons {
            grid-column: 2;
            grid-row: 2;
            grid-template-columns: max-content;
            place-content: center end;
            margin-top: 0;
        }

        &.has-image {
        
            grid-template-columns: max-content 1fr max-content;

            .article-buttons {            
                grid-column: 3;
            }
        }

        &.has-description .article-buttons {
            grid-template-columns: max-content max-content;
        }

    }

}

&.no-company-info-grid .article {
    @include media-breakpoint-up(sm) {
        gap: .2rem 2rem;
         
        h4 {
            grid-column: span 2;
        }

        .article-buttons {
            grid-column: 2;
            grid-row: 2;
            grid-template-columns: max-content;
            place-content: center end;
            margin-top: 0;
        }

        &.has-image {
        
            grid-template-columns: max-content 1fr max-content;

            .article-buttons {            
                grid-column: 3;
            }
        }

        &.has-description .article-buttons {
            grid-template-columns: max-content max-content;
        }
    }
}

.booking-resource {
    grid-template-columns: max-content 1fr;
    gap: 1rem 1.5rem;

    .booking-resource-picture {
        grid-column: 1;
        grid-row: 1;
        height: max-content;
    }

    h3 {
        grid-row: 1;
        align-self: center;
    }

    &.has-description .buttons-gap {
        grid-template-columns: 1fr 1fr;
        flex: 1 1 auto;

        .btn-primary {
            grid-column: 2;
        }

        .btn-linkalike {
            grid-row: 1;
        }
    }

    .buttons-gap {
        grid-template-columns: max-content;
        flex: 1 0 auto;
        place-content: end;
        grid-column: span 2;
    }

    p {
        flex: 1 0 100%;
    }

    &.child-resource {
        border-left: 3px solid $white_label_primary_color;
    }  
    
    @include media-breakpoint-only(sm) {
        gap: 1rem 2rem;

        &.has-description .buttons-gap {
            grid-template-columns: max-content max-content;
        }

        .booking-resource-picture {
            grid-row: 1 / span 2;
        }

        .buttons-gap {
            grid-row: 2;
            grid-column: 2;
        }
    }
    
    @include media-breakpoint-up(lg) {
        gap: 1rem 2rem;

        &.has-description .buttons-gap {
            grid-template-columns: max-content max-content;
        }

        .booking-resource-picture {
            grid-row: 1 / span 2;
        }

        .buttons-gap {
            grid-row: 2;
            grid-column: 2;
        }
    }

}

.vertical-separator {
    border: 1px solid $background_neutral_light;
    height: 0;

    &:last-of-type {
        display: none;
    }
}

.filter-resource {

    gap: 5%;
    transition: background-color $animation_transition;
    cursor: pointer;

    &:hover, 
    &:focus-within {
        background-color: $background_neutral_light;
    }

    .filter-resource-check {
        place-content: end;
        width: clamp(5rem, 30vw, 25%);
        align-self: start;

        .position-absolute {
            inset: 0;
        }
    }

    .form-check-input {
        z-index: 10;
    }

    .resource-image,
    .resource-initial {
        align-self: start;
    }

    .resource-image img {
        width: 100%;
    }

    .resource-initial {
        font-size: clamp(1.56rem, 1.3rem + 3vw, 2.2rem);
        place-content: center;
    }

    .resource-text {
        align-self: center;
    }

}
