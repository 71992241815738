@import "../abstracts/_variables.scss";

body {

    hr {
        border: 2px solid $primary_100;
        opacity: 1;
    }

    .line-with-word {
        width: 100%;
        overflow: hidden;
        text-align: center;

        & span {
            display: inline-block;
            white-space: nowrap;
            position: relative;

            &::before {
                margin-right: 2rem;
                text-align: start;
                right: 100%;
                background: #000000;
                content: '';
                height: 1px;
                position: absolute;
                top: 50%;
                width: 500px;
            }

            &::after {
                margin-left: 2rem;
                left: 100%;
                background: #000000;
                content: '';
                height: 1px;
                position: absolute;
                top: 50%;
                width: 500px;
            }
        }
    }

    .button-sizing .line-with-word {
        width: 190%;
        margin: 0 -45%;
    }

    @media screen and (max-width: 1200px) {
        .button-sizing .line-with-word {
            width: 150%;
            margin: 0 -25%;
        }
    }

    @media screen and (max-width: 975px) {
        .button-sizing .line-with-word {
            width: 100%;
            margin: 0;
        }
    }
}
