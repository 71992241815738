@import "../abstracts/_palette.scss";
@import "../abstracts/_variables.scss";

body {

    .searchable-select-toggle {
        text-align: left;
        width: 100%;
        padding: .5rem 2rem .5rem 1rem;
        cursor: pointer;
        background-color: white;
        font-weight: 400;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.6rem center;
        background-size: 1rem;

        &:hover {
            background-color: $neutral_10;
        }

        &:focus {
            box-shadow: $focus_box_shadow_size rgba($primary_100, .4);
        }
    }

    .searchable-select-searchbox-background {
        position: sticky;
        background-color: $neutral_10;
        top: 0;
    }

    .searchable-select-searchbox-input {
        font-size: 16px;
        padding: 14px 20px 12px 25px;
        border: none;
        border: 1px solid #ddd;
        width: 96%;
        margin: 2%;
    }

    .searchable-select-content {
        display: none;
        position: absolute;
        background-color: $neutral_10;
        width: 100%;
        border: 1px solid #ddd;
        z-index: 1;
        padding-inline-start: 0 !important;
        overflow-y: scroll;
        max-height: 400px;
        margin-right: 1rem;
    }

    .searchable-select-content li {
        color: black;
        padding: .8rem 1rem;
        display: block;

        &:hover {
            background-color: $neutral_30;
        }

        &:has(hr) {
            position: sticky;
            top: 4.3rem; //place it under search box input
            background-color: $neutral_10;
        }

        hr {
            margin-bottom: 0;
            border: 0;
            background-color: $neutral_50;
        }
    }
}
