@import "../abstracts/_palette.scss";
@import "../abstracts/_mixins.scss";
@import "../abstracts/_breakpoints.scss";

.week-navigation {
    flex-wrap: wrap;
    gap: $gap_default;

    @include media-breakpoint-only(sm) {
        flex-wrap: nowrap;
    }

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }
}

.year-and-week-picker {
    width: 100%;

    @include media-breakpoint-only(sm) {
        max-width: 40%;
    }

    @include media-breakpoint-up(lg) {
        max-width: 40%;
    }

    @include media-breakpoint-down(sm) {
        order: -1;
    }

    @include media-breakpoint-only(md) {
        order: -1;
    }

    .custom-select-container {
        max-width: 100%;
        white-space: nowrap;
    }

    .hidden-label {
        width: 0;
        overflow: hidden;
    }

    .hidden-select {
        display: inline-block;
        background-color: transparent;
        border: 0;
        width: auto;
        max-width: 100%;
        padding-right: 2rem;
        height: 100%;
        vertical-align: bottom;
        margin-left: -0.2rem;

        &:focus,
        &:focus-visible {
            outline: 0;
            box-shadow: none;
        }
    }

    .custom-select {
        background-color: white; /*use mixin for background-color?*/
        height: 100%;
        width: 100%;
        top: 0;
        pointer-events: none;
    }

    .hidden-select:hover ~ .custom-select {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, .05), rgba(0, 0, 0, .05));
    }

    .hidden-select:focus ~ .custom-select {
        @include primary-button-focus;
    }
}

.week-button {
    max-width: 45%;
    flex: 0 1 auto;
}

.timeslots-container {
    transition: color $animation_transition, 
                background-color $animation_transition, 
                box-shadow $animation_transition;

}

.grid-7-columns {
    grid-template-columns: repeat(7, 1fr);
    gap: clamp(.2rem, 2%, .8rem);
}

.fake-scrollbar-wrapper {
    max-height: 20rem;
    
    @include media-breakpoint-down(md) {
        max-height: 40vh;
    }
}

.timeslots {
    
    .timeslotColumn {
        grid-auto-rows: max-content;
        grid-auto-flow: row;
    }

    .blank-slot,
    .timeslot {
        font-size: .78rem;

        @include media-breakpoint-up(sm) {
            font-size: .89rem;
        }
    }

    .timeslot {
        padding-block: 0.15rem;
        border-radius: 0.3rem;
        width: 100%;
        background-color: $neutral_20;
        border: 2px solid transparent;
        color: $text_color_dark;

        &:hover {
            @include primary-button-hover-color;
            color: $text_color_light;
        }

        &:focus-visible {
            @include primary-button-focus;
            outline: 2px transparent;
        }

        &:focus {
            outline: 2px transparent;
        }

        &.selected {
            @include primary-button-color;
            color: $text_color_light;
        }
    }

}

.timeslot.promotion-timeslot,
.day-bubble.promotion-timeslot {
    border-color: $danger;
}

.timeslot.premium-timeslot,
.day-bubble.premium-timeslot {
    background-image: linear-gradient( $neutral_20, $neutral_20), linear-gradient(110deg, darkgoldenrod, gold 30%, darkgoldenrod 45%);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    &:hover {
        background-image: linear-gradient( var(--button-color-hover, $primary_130), var(--button-color-hover, $primary_130)), linear-gradient(110deg, darkgoldenrod 20%, gold 50%, darkgoldenrod 75%);
    }

    &.selected {
        background-image: linear-gradient($white_label_primary_color, $white_label_primary_color), linear-gradient(110deg, darkgoldenrod, gold 30%, darkgoldenrod 45%);
    }
}

.promotion-time {
    display: inline-block;
    width: .7rem;
    aspect-ratio: 1 / 1;
    background-color: $danger;
}

.premium-time {
    display: inline-block;
    width: .7rem;
    aspect-ratio: 1 / 1;
    background-image: linear-gradient(120deg, darkgoldenrod, gold 40%, darkgoldenrod 70%);
}

.next-time-container {
    margin: 2em auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .next-available-time-text {
        font-size: clamp(1.11rem, .75rem + 2vw, 1.56rem);
        margin-bottom: .5rem;
    }
}


.resource-picker {
    max-height: 28rem;
    grid-auto-flow: column;
    grid-auto-columns: 5rem;
    grid-auto-rows: max-content;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
        grid-auto-flow: row;
        grid-auto-columns: 100%;
        max-width: 15%;
    }
}

.time-picker-resource {
    width: 100%;
    border: 0;
    background-color: transparent;
    padding: 0;
    color: $text_color_dark;

    > span {
        width: inherit;
    }
}

.resourceImage,
.resource-picture-circle {
    border-radius: 50%;
    border: none;
    margin: 0.25rem;
    width: 3rem !important;
    height: 3rem !important;
    min-height: 3rem;
    object-fit: cover;
}

.resourceImage.selected,
.resource-picture-circle.selected {
    @include primary-button-focus;
}

.resource-picture-initals {
    text-align: center !important;
    line-height: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: $text_color_light;
    @include primary-button-color;
    font-family: $font_family;
}

.all-resources-picture {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34.89 34.89'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff%3B%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='m22.39 25c-6.89 0-12.5-5.61-12.5-12.5S15.5 0 22.39 0s12.5 5.61 12.5 12.5-5.61 12.5-12.5 12.5Zm0-23.67c-6.16 0-11.17 5.01-11.17 11.17s5.01 11.17 11.17 11.17 11.17-5.01 11.17-11.17S28.55 1.33 22.39 1.33Z'/%3E%3Cpath class='cls-1' d='m17.39 28.67c-6.16 0-11.17-5.01-11.17-11.17 0-2.89 1.11-5.52 2.92-7.5.16-.84.4-1.66.71-2.44-3 2.29-4.95 5.88-4.95 9.94 0 6.89 5.61 12.5 12.5 12.5 4.05 0 7.65-1.95 9.94-4.95-.78.31-1.59.55-2.44.71-1.99 1.8-4.61 2.92-7.5 2.92Z'/%3E%3Cpath class='cls-1' d='m12.5 33.57c-6.16 0-11.17-5.01-11.17-11.17 0-2.89 1.11-5.52 2.92-7.5.16-.84.4-1.66.71-2.44C1.95 14.74 0 18.34 0 22.39c0 6.89 5.61 12.5 12.5 12.5 4.05 0 7.65-1.95 9.94-4.95-.78.31-1.59.55-2.44.71-1.99 1.8-4.61 2.92-7.5 2.92Z'/%3E%3C/svg%3E");
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: center;
}

.day {
    font-size: clamp(.78rem, .2rem + 2vw, 1rem);

    .daynumber {
        font-size: 1rem;
    }
}
