@import "../../abstracts/_palette.scss";
@import "../../abstracts/_breakpoints.scss";

body .front-page {

    .brands-grid {
        overflow: hidden;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 4rem;
        grid-auto-rows: 0;
        justify-items: center;
        align-items: center;
        column-gap: 1rem;

        @include media-breakpoint-up(sm) {
            grid-template-columns: repeat(5, 1fr);
        }

        @include media-breakpoint-up(md) {
            width: 80%;
            grid-template-rows: 6rem;
        }

        svg {
            max-height: 100%;
            max-width: 99%;
        }

        .brand-logo {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .review-carousel {
        height: 35rem;
        width: calc(100vw - 1.5rem);

        @include media-breakpoint-only(sm) {
            height: 30rem;
        }

        @include media-breakpoint-only(md) {
            padding-inline: 4rem;
            height: 28rem;
        }

        @include media-breakpoint-up(lg) {
            padding-inline: 6rem;
            height: 20rem;
        }
        
        @include media-breakpoint-up(xl) {
            width: 90%;
        }

        .carousel-item.active {
            display: flex;
        }

        .carousel-item {
            transition: transform 0.8s ease-in-out;
            margin-block: auto;
        }


        .review-card {
            background-color: $neutral_140;

            @include media-breakpoint-down(md) {
                margin-top: 5rem;
                margin-inline: 1rem;
                padding-top: 4rem;
            }

            @include media-breakpoint-up(md) {
                margin-inline: 5rem;
                padding-left: 5rem;
            }
        }

        .review-image {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10rem;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                top: 50%;
                left: 0;
            }

            img {
                width: 101%;
            }
        }
    }


    .carousel-buttons {
        top: 2rem;

        @include media-breakpoint-up(lg) {
            left: 7%;
        }

        .bi::before {
            font-size: 2rem;
            font-weight: 400 !important;
        }
    }
}
