@import "../abstracts/_palette.scss";

.payment-summary {
    table-layout: fixed;
    caption-side: top;

    caption {
        color: inherit;
    }

    tr > :nth-child(odd) {
        text-align: start;
        vertical-align: top;
    }

    tr > :nth-child(even) {
        text-align: end;
        vertical-align: top;
        padding-left: 1rem;
    }

    .booking-description {
        padding-left: 2rem;
        max-width: 90%;
    }

    table {
        margin-left: 1rem;
        width: calc(100% - 1rem);
        margin-block: .5rem;

        tbody tr > :nth-child(odd) {
            padding-left: 1rem;
        }
    }

    > tbody > :last-child * {
        padding-bottom: .5rem;
    }

    tfoot {
        border-top: 2px solid $neutral_110;

        > :first-child * {
            padding-top: .5rem;
        }
    }
}
