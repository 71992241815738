@import "../../abstracts/_palette.scss";

body {
    #menu-wrapper.isMobile {
        border-radius: 3px;
        color: $neutral_150;
    }

    #page-wrapper #menu-wrapper #system-menu-drop-down {
        padding-bottom: .6rem;
        padding-top: .6rem;
        border: 2px solid $neutral_150;
        border-radius: 3px;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;

        span {
            font-weight: 600;

            &.caret {
                display: none;
            }
        }
    }
}
