@import "../abstracts/_variables.scss";
@import "../abstracts/_breakpoints.scss";
@import "../abstracts/_mixins.scss";

body {

    .modal-wrapper {
        top: 0;
        z-index: 1055;
    }

    dialog.modal-box {
        position: fixed;
        border: 0;
        padding: 0;
        display: none;
        transition: opacity $animation_transition;
        opacity: 0;

        &[open] {
            display: flex;
            opacity: 1;
        }

        &:not([open]) {
            pointer-events: none;
        }

        &::backdrop {
            background-color: rgba(black, .5);
        }
        //fix for modals in safari
        &.modal-content {
            height: auto;
            inset-block-end: auto;
        }
    }

    .modal-dialog {
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        width: calc(100% - 3rem);
        margin-block: 0 !important;
        margin-inline: auto;
        max-height: calc(100% - 3rem);
    }

    .modal-fade:not([open]) {
        opacity: 0;
    }

    .modal-box {
        border-radius: $container_border_radius_small;
        border: none;
        box-shadow: 0 0.15rem 0.4rem 0 rgba(0, 0, 0, 0.16);

        &.square-modal {
            border-radius: 0;
        }

        &.booking-info-modal {
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 1px solid #888;
        }

        .modal-header {
            padding: 1rem 1rem 0rem;
            border-bottom: none;
            align-items: normal;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }
    }

    .modal-medium {
        max-width: 600px;
    }

    .modal-body {
        max-height: 100%;
        overflow: hidden auto;
    

        //scrollbar in modal
        @include firefox-scroller-colors;
        scrollbar-width: thin;
        //Other browsers
        //Width
        &::-webkit-scrollbar {
            width: .6rem;
            height: .6rem;
        }
        //Track
        &::-webkit-scrollbar-track {
            background: $neutral_10;
            border-radius: .3rem;
        }
        //Handle
        &::-webkit-scrollbar-thumb {
            @include primary-button-color;
            border-radius: .3rem;
        }
        //Handle on hover
        &::-webkit-scrollbar-thumb:hover {
            @include primary-button-hover-color;
        }
    }

    .modal-footer > * {
        margin: 0;
    }

    .modal-dialog {
        @media (max-height: 400px) {
            position: relative !important;
            max-height: unset !important;
            top: 1.5rem;
            transform: unset;

            .modal-body {
                overflow: visible visible;
            }
        }
    }

    .scroll-entire-modal-on-smaller-screen-height .modal-dialog {
        @media (max-height: 800px) {
            position: relative !important;
            max-height: unset !important;
            top: 1.5rem;
            transform: unset;

            .modal-body {
                overflow: visible visible;
            }
        }
    }

    
}
