@import "../abstracts/_variables.scss";

body {

    .button-group-background {
        border-radius: 100rem;
        padding: 0.4rem;
        background-color: $primary_70;
    }

    .btn-group .button-group-button {
        border: solid;
        border-width: 2px;
        border-color: transparent;
        background: none;
        padding: 0.4rem;

        &.active {
            color: $primary_10;
            background-color: $boka_primary_color;
            border-color: $primary_10;
            z-index: 2;

            &:hover {
                background-color: $primary_130;
            }
        }

        &:hover {
            background-color: $primary_90;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba($primary_10, 0.25);
        }
    }

    .btn-group .pricing-btn-group-button {
        background-color: $primary_130;
        padding: .5rem 1rem;
        border: 1px solid $primary_130;
        border-radius: .25rem;
        font-size: .81rem;
        font-weight: 500;

        &.active {
            background-color: white;
            color: $primary_130;
        }

        &:hover {
            background-color: white;
            color: $primary_130;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba($primary_130, 0.25);
        }
    }
}
