@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_variables.scss";

body .front-page {
    .front-page-hero {
        background-image: radial-gradient(circle farthest-corner at 60% 110%, white, $secondary_10 90%);

        @include media-breakpoint-up(md) {
            background-image: radial-gradient(circle farthest-corner at 60% -10%, white, $secondary_10 90%);
        }
    }

    .front-page-hero-text {
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(md) {
            width: 45%;
        }
    }

    .front-page-hero-image {
        width: 100%;

        @include media-breakpoint-up(md) {
            position: absolute;
            inset: 0 0 0 auto;
            height: 100%;
            width: auto;
            object-fit: cover;
        }

        @include media-breakpoint-only(md) {
            inset: 0 min(-15%, 5rem) 0 auto;
        }

        @include media-breakpoint-between(md, xl) {
            mask-image: linear-gradient(100deg, rgba(black, .1) 35%, black 75%);
        }
    }

    .frontpage-search-box {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $primary_20;
            border-bottom-left-radius: .5rem;
            box-shadow: $box-shadow;

            input {
                min-width: 35ch;
            }
        }

        @include media-breakpoint-up(lg) {
            border-bottom-right-radius: .5rem;
            right: 1.3rem;
        }
    }

    .price-tag {
        background-color: $coral_100;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        transform: rotate(-7deg);
        place-content: center;
        width: calc(10ch + 8vw);

        .price-tag-text {
            font-size: clamp(1.33rem, 1rem + 2vw, 1.78rem);
            font-weight: 900;
            line-height: 120%;
        }

        @include media-breakpoint-up(md) {
            width: 15ch;
            right: 35%;
            top: 0;
            transform: rotate(7deg) translateX(220%);
        }
    }

    .section-margin {
        margin-block: 2.8rem;

        @include media-breakpoint-up(md) {
            margin-block: 5.6rem
        }
    }

    .puff {
        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }

        @include media-breakpoint-up(sm) {
            flex: 1 1 40%;
            max-width: 45%;
        }

        @include media-breakpoint-up(md) {
            flex: 1 1 0%;
            max-width: 30%;
        }

        img {
            width: 100%;
            aspect-ratio: 2;
            object-fit: cover;
            box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.3);
        }
    }

    .carousel-indicators {
        position: unset;

        [data-bs-target] {
            width: .7rem;
            height: .7rem;
            border-radius: 1rem;
            background-color: transparent;
            background-clip: unset;
            border: 2px solid $boka_primary_color;
            opacity: 1;
            margin-inline: .4rem;
            transition: background-color .35s ease-in-out;
            flex: 0 0 auto;
        }

        .active {
            background-color: $boka_primary_color;
        }
    }


    .half-screen-image-right,
    .half-image-left {
        aspect-ratio: 1.75/1;
        width: 100%;
        object-fit: cover;
    }


    .half-screen-image-right {

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            width: 50%;
        }
    }

    .text-left {

        @include media-breakpoint-up(md) {
            padding-inline: 3rem 4rem;
            aspect-ratio: 6/5;
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            padding-inline: 0 6rem;
            aspect-ratio: 6/5;
        }
    }

    .text-right {
        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            padding-inline: 3rem 2rem;
        }

        @include media-breakpoint-up(lg) {
            padding-inline: 4rem;
        }

        .bi::before {
            vertical-align: middle;
        }
    }

    .half-image-left {
        @include media-breakpoint-up(md) {
            aspect-ratio: 6/5;
            min-height: 100%;
        }
    }

    .align-with-text {
        margin-left: 2.4rem;
    }
}
