@import "../../abstracts/_palette.scss";
@import "../../abstracts/_variables.scss";

body {
    .recently-visited-item {
        color: $text_color_dark;
        overflow: hidden;

        .item-title-container {
            display: inline-block;
            max-width: 85%;
            background-color: $primary_10;
            position: absolute;
            bottom: 0;
            transform: translateY(50%);
            left: 5%;
            padding: .2rem .75rem .3rem;
            z-index: 2;

            .item-title {
                font-size: min(.35rem + 2vw, 1rem);
                font-weight: 600;
                margin-bottom: .1rem;
            }

            .item-rating-text {
                font-size: min(.25rem + 1.3vw, .72rem);
                font-weight: 400;
                margin-bottom: 0;
                white-space: nowrap;

                strong {
                    padding-left: .2rem;
                }
            }

            .item-stars {
                margin-right: .2em;
            }
        }


        .item-image {
            width: 100%;
            height: 100%;
            aspect-ratio: calc(16/9);
            object-fit: cover;
        }
    }
    
    
    //hover animation
    .recently-visited-item:hover .item-title-container {
        background-color: $primary_20;
    }

    .item-hover-effect .item-image {
        -webkit-transition: all .15s linear;
        transition: all .15s linear;
    }

    .item-hover-effect:hover .item-image {
        -ms-transform: scale(1.125);
        -webkit-transform: scale(1.125);
        transform: scale(1.125);
    }
}
