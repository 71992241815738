@import "../abstracts/_palette.scss";

body {

    .myupcomingBookingsContentContainer {
        min-height: 25vh;
    }

    #myupcomingBookingsContent {

        .accordion-header {
            .accordion-button {
                padding-left: 0;
            }
        }

        h3 {
            font-size: 18px;
        }

        h4 {
            font-size: 15px;
        }

        span {
            font-size: 14px;
        }

        p {
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    .upcoming-header-count {
        background-color: $boka_primary_color;
        color: white;
        padding: 0.25em 0.75em 0.25em 0.75em;
    }

    .no-upcoming-text {
        font-style: italic;
    }

    .my-bookings-page-size {
        cursor: pointer;
        font-size: 12px;
        color: black;
    }

    .my-upcoming-bookings-content-container {
        //width: 50%;
    }

    .my-bookings-page-size.selected {
        text-decoration: underline;
    }

    .mypage-list-info-icon {
        display: block;
        color: #337ab7;
        font-size: 24px;
        font-weight: 300;
        margin-top: 5px;
    }

    .mypage-list-calendar-icon {
        color: $primary;
        font-size: 18px;
        margin-top: 8px;
    }

    .mypage-list-group-calendar-icon {
        font-size: 18px;
        margin-top: 8px;
    }

    .mypage-list-info-icon {
        display: block;
        color: #337ab7;
        font-size: 24px;
        font-weight: 300;
        margin-top: 5px;
    }

    .mypage-list-calendar-icon {
        color: $primary;
        font-size: 18px;
        margin-top: 8px;
    }

    .mypage-list-group-calendar-icon {
        font-size: 18px;
        margin-top: 8px;
    }

    #bookingInfoModal {
        div.modal-content {
            max-height: 100%;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            -o-box-shadow: none;
            box-shadow: none;
            overflow: auto;
        }
    }

    .booking-ongoing {
        color: $boka_primary_color !important;
    }
}
