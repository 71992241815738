@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

body {
    .with-arrows {

        .page-link {
            border: none;
            background-color: transparent;
            padding: 0;
            cursor: pointer;

            &:hover {
                background-color: $background_neutral_light;
            }
        }

        .page-item .page-link {
            width: 2.25rem;
            line-height: 2.25rem;
            text-align: center;
            border-radius: 50%;
            margin: 0 .2rem;
        }

        .page-item.active .page-link {
            color: $text_color_light;
            @include primary-button-color;

            &:hover {
                @include primary-button-hover-color;
            }
        }

        .page-item:last-child .page-link {
            border-radius: 50%;
        }

        .page-item:last-child .page-link {
            border-radius: 50%;
        }

        .page-item:first-child .page-link {
            border-radius: 50%;
        }

        .page-link .big-body-text {
            width: 30rem;
        }

        .page-link .bi::before {
            vertical-align: middle;
        }

        .page-link .bi-chevron-right::before {
            padding-left: .1rem;
        }

        .page-link .bi-chevron-left::before {
            padding-right: .2rem;
        }
    }
}
