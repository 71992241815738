@import "../abstracts/_variables.scss";

body {

    .form-label-radio-grid {
        grid-template-columns: max-content auto;
        width: fit-content;
        font-weight: 500;

        //to align it with other inputs since it has a bit of padding
        margin-left: -.25em;
        grid-column-gap: .75em;
    }


    @media not print {

        input[type="radio"] {
            appearance: none;
            width: 1.5em;
            height: 1.5em;
            vertical-align: bottom;
            border-radius: 50%;
            mask-image: url("data:image/svg+xml,%3Csvg width='1.5em' height='1.5em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='9' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 100%;
            background-color: $neutral_100;
            transition: mask-image $animation_transition background-color $animation_transition;
        }

        input[type="radio"]:checked {
            mask-image: url("data:image/svg+xml,%3Csvg width='1.5em' height='1.5em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='9' stroke='black' stroke-width='2'/%3E%3Ccircle cx='16' cy='16' r='5' fill='black'/%3E%3C/svg%3E");
            mask-repeat: no-repeat;
            background-color: $white_label_primary_color;
        }

        input[type="radio"]:focus-visible {
            mask-image: url("data:image/svg+xml,%3Csvg width='1.5em' height='1.5em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='13' stroke='black' stroke-opacity='0.25' stroke-width='6'/%3E%3Ccircle cx='16' cy='16' r='9' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
        }

        input[type="radio"]:focus:checked {
            mask-image: url("data:image/svg+xml,%3Csvg width='1.5em' height='1.5em' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='16' cy='16' r='13' stroke='black' stroke-opacity='0.25' stroke-width='6'/%3E%3Ccircle cx='16' cy='16' r='9' stroke='black' stroke-width='2'/%3E%3Ccircle cx='16' cy='16' r='5' fill='black'/%3E%3C/svg%3E");
        }
    }
}
