@import "../abstracts/_variables.scss";

body {
    .navbar-boka-logo-height {
        height: $navbar_logo_height;
    }

    .navbar-custom-logo-size {
        height: calc($navbar_logo_height + .5rem);
        max-width: clamp(9rem, 6rem + 20vw, 20rem);
        object-fit: contain;
    }
        
    .footer-logo-height {
        height: 55px;
        padding-top: 3px;
    }

    .footer-border-logos-height {
        height: 20px;
    }
}
