@import "../../abstracts/_mixins.scss";

img {    
        object-fit: cover;
}

.anchor-link {
    appearance: none;
    background-color: transparent;
    border: 0;
    @include link-text-color;
}

.horizontal-divider-dot {
    width: .3em;
    height: .3em;
    border-radius: 100%;
    @include primary-button-color;

    &:last-of-type {
        display: none;
    }
}
