.my-reconnect-modal > div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    background-color: rgba(255,255,255, 0.7);
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: clamp(1.11rem, .75rem + 2vw, 1.56rem);
}

.components-reconnect-hide,
.components-reconnect-rejected > div,
.components-reconnect-failed > div,
.components-reconnect-show > div,
.components-reconnect-hide > div {
    display: none;
}

.components-reconnect-failed .failed,
.components-reconnect-show .show,
.components-reconnect-rejected .rejected {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
