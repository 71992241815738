@import "../../abstracts/_palette.scss";

.review {
    > div {
        grid-template-columns: 1fr max-content;
    }

    [class*="bi-star"] {
        color: $yellow_100;
    }
}
