@import "../abstracts/_mixins.scss";
@import "../abstracts/_variables.scss";
@import "../abstracts/_palette.scss";

body {

    .homepage-hero-small-text {
        font-size: 1.11rem;
        line-height: 144%;
        font-weight: 500;
    }

    .homepage-hero-heading {
        word-break: break-word;
        font-size: 2.56rem;
        line-height: 122%;
        font-weight: 900;
    }

    .linked-header-gate-text {
        font-size: min(.5rem + 3vw, 1.56rem);
        line-height: 142%;
        font-weight: 600;
        text-decoration: none;
        white-space: normal;
        color: $primary_120;

        .homepage-hero-link {
            font-size: min(.4rem + 3vw, 1.33rem);
            font-weight: 500;

            i.bi.bi-arrow-right-circle {
                font-size: min(1rem + 2vw, 1.78rem);
                font-weight: 500;
            }
        }

        .start-hero-gate-text {
            font-size: min(.35rem + 3vw, 1.56rem);
        }
    }

    .category-color-default .linked-header-gate-text {
        color: white;
    }

    @media screen and (max-width: 350px) {
        .start-hero-gate-text {
            display: inline-block;
            width: min-content;
            margin-right: .5rem;
        }
    }

    .hero-background-behind-button {
        @include category-linear-gradient;
    }

    .linked-header-gate-text.text-arrow-group .bi-arrow-right-circle {
        font-size: min(1rem + 3vw, 2.56rem);
    }

    .arrow-animation {
        transition: padding $animation_transition;
    }

    .linked-header-gate-text.text-arrow-group:hover {
        cursor: pointer;
        @include linked-header-gate-text-color-light;
        //color: $primary_100;

        .arrow-animation {
            padding-left: 1.5rem !important;
        }
    }
}
