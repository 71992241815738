@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_variables.scss";


#external-events-column {
    width: auto;

    #external-events {
        background-color: transparent !important;
    }
}

#external-events-column .buttons-gap {
    grid-template-columns: 1fr 1fr;

    @include media-breakpoint-up(md) {
        grid-template-columns: 100%;
    }

    .fc-event {
        margin: 0;
        border-radius: .25rem;
        width: auto !important;
        grid-template-columns: 1fr max-content;

        span {
            width: auto;
            max-width: 100%;
        }
    }
}

.scheduling-buttons {
    grid-auto-rows: max-content;

    @include media-breakpoint-down(sm) {
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: column;

        button:first-of-type {
            grid-column: 2;
            grid-row: 1;
        }
    }

    @include media-breakpoint-only(sm) {
        grid-template-columns: repeat(3, 1fr);

        button:first-of-type {
            grid-row: 2;
        }
    }


    @include media-breakpoint-down(md) {
        button.btn.old-button-override {
            padding: 0.67rem .5rem;
        }
    }
}

.toggle-collapse::after {
    content: "\F282";
    font-family: bootstrap-icons;
    vertical-align: sub;
}

.toggle-collapse[aria-expanded="true"]::after {
    content: "\F286";
}

.multiselect.dropdown-toggle {
    border-radius: 5rem;
    padding: $button_padding_y $button_padding_x !important;
    line-height: 129% !important;
    height: auto;
}

.multiselect-container {
    width: 100%;
    
    a {
        padding: .2rem 0;
    }

    label {
        padding: .2rem 1rem .2rem 1.5rem;
    }
}

#scheduling .multiselect-selected-text {
    @include media-breakpoint-up(md) {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 11ch;
        vertical-align: bottom;
    }
}


//calendar
#schedule-calendar {
    padding-bottom: 1rem;

    .fc-scroller.fc-time-grid-container {
        overflow: hidden !important;
    }

    .btn-group {
        margin: 0;
        padding: 0;
        flex-wrap: nowrap;

        .btn {
            line-height: initial;
            height: auto;
            border-radius: 0;
            padding-inline: .6rem;

            &:first-child {
                border-top-left-radius: $button_border_radius;
                border-bottom-left-radius: $button_border_radius;
                padding-left: $button_padding_x_small;
            }

            &:last-child {
                border-top-right-radius: $button_border_radius;
                border-bottom-right-radius: $button_border_radius;
                padding-right: $button_padding_x_small;
            }
        }
    }

    button.disabled {
        color: $text_color_neutral_dark;
        background-color: $background_color_light;
    }

    @include media-breakpoint-up(sm) {
        .fc-toolbar {
            justify-content: space-between;
        }
    }

    @include media-breakpoint-only(lg) {
        flex-wrap: nowrap;

        .fc-center {
            flex: 1 1 auto;
        }

        .fc-right {
            order: 2;
        }
    }

    @include media-breakpoint-up(xxl) {
        flex-wrap: nowrap;

        .fc-center {
            flex: 1 1 auto;
        }

        .fc-right {
            order: 2;
        }
    }


    .table-bordered {
        border: 0;

        tr,
        th,
        td {
            border: 0;
        }

        thead .table-bordered thead {
            border: 0;

            th,
            td {
                border-top: 0;
                vertical-align: middle;
            }

            .fc-day-header,
            .fc-resource-cell {
                padding-block: .3rem;
                text-align: center;
            }
        }

        .table-bordered {
            th,
            td {
                border-top: .5px solid $neutral_80;
            }

            .fc-minor td {
                border-top: .5px dotted $neutral_80;
            }
        }

        .fc-bg td,
        thead thead th {
            border: 1px solid $neutral_80;
            background-clip: padding-box;
        }
    }

    .fc-month-view .table-bordered {

        .fc-day-header span span {
            vertical-align: bottom;
        }

        .fc-resource-cell,
        thead :nth-child(7n+2),
        .fc-day:nth-child(7n+2) {
            border-left-width: 3px;
        }

        thead :nth-child(2),
        .fc-day:nth-child(2) {
            border-left-width: 1px;
        }

        .fc-day-top {
            padding-inline: .5rem;
        }

        .fc-day-top.fc-today {
            padding: 0 .5rem;
            font-size: 1rem;
        }

        .fc-content-skeleton td {
            border: 0;
        }

        .fc-disabled-day {
            background-color: $neutral_10;
        }
    }

    &.fc-ltr .fc-time-grid .fc-event-container {
        margin: 0 1%;

        .fc-content {
            padding-inline: 1%;
            padding-top: .2rem;
        }
    }

    .holiday {
        color: #DC3545;
    }
}

//modals
#create-time-type-dialog,
#link-articles-modal {

    label {
        margin-bottom: 0;
    }

    .form-control {
        border: 1px solid $input_border_color;
        //to make the border outside the white background
        border-image: linear-gradient($input_border_color, $input_border_color) 1;
        border-image-outset: 1;
        border-radius: 0;
        height: unset;
        font-size: 1rem;
        line-height: 150%;

        &:not(:focus) {
            box-shadow: none;
        }
    }
}

#create-time-type-dialog {

    @include media-breakpoint-up(sm) {
        max-width: 22rem;
    }


    .sp-replacer {
        border: 1px solid $input_border_color;
        //to make the border outside the white background
        border-image: linear-gradient($input_border_color, $input_border_color) 1;
        border-image-outset: 1;
        border-radius: 0;
        height: unset;
        font-size: 1rem;
        line-height: 150%;
        background-color: unset;
        padding: 0.625rem 2rem 0.625rem 0.75rem;
        background-position: right .6rem center;
    }

    input[type="number"] {
        appearance: textfield;
        text-align: end;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
            opacity: 1;
        }
    }

    .price-negative {
        right: 6.2rem;
        top: 50%;
        transform: translateY(-50%);
        max-width: calc(100% - 7rem);
        overflow: hidden;
        pointer-events: none;
        direction: rtl;
        white-space: nowrap;
    }

    #minus-offset {
        color: transparent;
    }
}

#link-articles-modal {
    @include media-breakpoint-up(sm) {
        max-width: 25rem;
    }

    .articles-to-link {
        max-height: 20rem;
        overflow-y: auto;

        //to make sure checkboxes doen't get clipped by overflow
        padding-left: 1rem;
        margin-left: -1rem;

        label span {
            max-width: 25ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
