@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

body {

    .form-label-checkbox-grid {
        grid-template-columns: max-content auto;
        grid-column-gap: 1em;
        width: fit-content;

        .validation-message {
            grid-column: 2;
        }
    }

    .form-check-input[type="checkbox"] {
        border-radius: 0;
        border: 1px solid $input_border_color;
        //to make the border outside the white background
        border-image: linear-gradient($input_border_color, $input_border_color) 1;
        border-image-outset: 1;


        &:checked {
            @include primary-button-color;
            border-image: linear-gradient($white_label_primary_color, $white_label_primary_color) 1;
        }

        &:focus {
            @include primary-button-focus;
        }
    }

    .checkbox-secondary {
        &:checked {
            background-color: $secondary_115;
        }

        &:focus {
            box-shadow: $focus_box_shadow_size $secondary_focus_color;
        }
    }
}
