//https://sass-guidelin.es/#the-7-1-pattern

@import "abstracts/_variables.scss";
@import "vendors/bootstrap/_bootstrapCustom.scss";
@import "vendors/_colorPickerPalette.scss";
@import "base/_utilities.scss";

html {
    font-size: 18px;

    &:has( .company-page ){
         scroll-padding-block: $navbar_height_rem; 

        @include media-breakpoint-up(sm) {
            scroll-padding-block: 10rem; 
        }
    }

}

.offcanvas-backdrop.show ~ .offcanvas-backdrop.show,
.modal-backdrop.show ~ .modal-backdrop.show {
    opacity: 0 !important;
}

:not(.bs-3) {
    @import "vendors/_grecaptcha.scss";
    @import "vendors/_fullCalendarDefault.scss";

    @import "node_modules/@yaireo/fakescroll/fakescroll";
    @import "node_modules/jquery-autocomplete/jquery.autocomplete";

    @import "base/typography.scss";
    @import "base/_base.scss";
    @import "base/_list.scss";

    @import "layout/_navigation.scss";
    @import "layout/_footer.scss";
    @import "layout/_hero.scss";
    @import "layout/_cookie.scss";
    @import "layout/_searchBox.scss";
    @import "layout/_myUpcomingBookings.scss";
    @import "layout/_reconnectModal.scss";
    @import "layout/_grids.scss";
    @import "layout/_horizontalScroller.scss";
    @import "layout/_newsBannerOverride.scss";

    @import "components/_buttons.scss";
    @import "components/_dropdown.scss";
    @import "components/_category.scss";
    @import "components/_accordion.scss";
    @import "components/_inputfield.scss";
    @import "components/_forms.scss";
    @import "components/_select.scss";
    @import "components/_cardButton.scss";
    @import "components/_hr.scss";
    @import "components/_offcanvas.scss";
    @import "components/_modal.scss";
    @import "components/_checkbox.scss";
    @import "components/_radio.scss";
    @import "components/_buttonGroup.scss";
    @import "components/_logo.scss";
    @import "components/_focusCategoryItem.scss";
    @import "components/_container.scss";
    @import "components/_bokaLoader.scss";
    @import "components/_spinner.scss";
    @import "components/_pagination.scss";
    @import "components/_ldsHeart.scss";
    @import "components/_colorPicker.scss";
    @import "components/_searchableSelect.scss";
    @import "components/_scrollbar.scss";
    @import "components/_timeslotpicker.scss";
    @import "components/_detailsAndSummary.scss";
    @import "components/_numberInputWithButtons.scss";
    @import "components/_paymentSummaryTable.scss";
    @import "components/_inputWithButton.scss";
    @import "components/_tableAdmin.scss";


    @import "pages/homepage/_frontpage.scss";
    @import "pages/homepage/_logosAndReviews.scss";
    @import "pages/homepage/_marketplaceBanner.scss";
    @import "pages/marketplace/_recentlyVisistedItem.scss";
    @import "pages/marketplace/_homepageGrids.scss";
    @import "pages/_pricing.scss";
    @import "pages/_aboutPage.scss";
    @import "pages/mypage/_articlesAndServices.scss";
    @import "pages/mypage/_campaigns.scss";
    @import "pages/mypage/_loyaltyProgram.scss";
    @import "pages/mypage/_qualifications.scss";
    @import "pages/mypage/_servicePackages.scss";
    @import "pages/mypage/_systemSettings.scss";
    @import "pages/mypage/_calendarSettings.scss";
    @import "pages/mypage/_mobileMenuDropdown.scss";
    @import "pages/mypage/_modalsOverride.scss";
    @import "pages/mypage/_bookingRules.scss";
    @import "pages/mypage/_selectOverride.scss";
    @import "pages/mypage/_fortnoxSettings.scss";
    @import "pages/mypage/_fortnoxLandingPage.scss";
    @import "pages/mypage/_schedulingNew.scss";
    @import "pages/categoriesAndSearchPage/_searchPageControls.scss";
    @import "pages/categoriesAndSearchPage/_supplierEventcard.scss";
    @import "pages/calendar/calendar.scss";

    @import "themes/_colors.scss";
}

@import "pages/companypage/base.scss";

