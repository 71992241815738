@import "../abstracts/_palette.scss";

body {

    .pricing-card {
        width: 30%;
        min-width: 14rem;
        margin-top: 1rem;

        &:hover {
            //box shadow
        }

        hr {
            border: 1px solid black;
            opacity: .25;
        }

        p {
            font-size: .87rem;

            &.pricing-small-text {
                font-size: .67rem;
                padding-top: .2rem;
            }
        }

        li {
            font-size: .87rem;
        }
    }

    .pricing-card-title {
        &.pricing-first-card {
            background-color: $neutral_120;
        }

        &.pricing-second-card {
            background-color: $coral_120;
        }

        &.pricing-third-card {
            background-color: $pink_120;
        }

        &.pricing-fourth-card {
            background-color: $orange_120;
        }

        &.pricing-fifth-card {
            background-color: $yellow_120;
        }

        &.pricing-sixth-card {
            background-color: $blue_120;
        }

        color: white;
    }

    .btn-custom.pricing-btn-group-button {
        background-color: $primary_130;
        padding: .5rem 1rem;
        border: 1px solid $primary_130;
        border-radius: .25rem;
        font-size: .81rem;
        font-weight: 500;

        &.active {
            background-color: white;
            color: $primary_130;
        }

        &:hover {
            background-color: white;
            color: $primary_130;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgba($primary_130, 0.25);
        }
    }
}
