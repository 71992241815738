body {

    .number-input .form-control {
        width: 4ch;
        appearance: textfield;
        margin-top: 0;
        padding: .2rem 0;
        line-height: normal;
    }

    .number-input .btn {
        font-size: 1rem;
        line-height: 1rem;
        padding: 0.2rem;
    }
}
