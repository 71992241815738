@import '../abstracts/_variables.scss';
@import '../abstracts/_palette.scss';
@import "../abstracts/_mixins.scss";
@import "../abstracts/_breakpoints.scss";

body {

    input {
        font-family: $font_family;
    }

    .form-control {
        border: 1px solid $input_border_color;
        //to make the border outside the white background
        border-image: linear-gradient($input_border_color, $input_border_color) 1;
        border-image-outset: 1;
        background-clip: unset;

        &:focus {
            @include primary-button-focus;
        }
    }

    .secondary-focus {
        &:focus {
            box-shadow: $focus_box_shadow_size $secondary_focus_color;
        }
    }

    //padding wouldn't work on a label in one inputfield, therefore left override
    .form-floating > label {
        left: auto;
    }

    .whitelabel-input {
        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }
}
