body {
    #presentation-form {
        #presentation-description {
            border: 1px solid rgba(0,0,0,0.2);
        }
    }

    #presentation-RankAndRateLinkText {
        border: 1px solid rgba(0,0,0,0.2);
    }

    #opening-hours {
        .initialism {
            margin-right: 10px;
        }
    }

    #question_VisibleTeamMember, #questionEnableBookingConfirmations, #questionCalendarOnlyVisibleForadmins, #questionAllowExternalBookings {
        margin-left: 10px;
    }
}
