@import "../abstracts/_breakpoints.scss";

.grid-flow-column {
    grid-auto-flow: column;
}

.grid-flow-row {
    grid-auto-flow: row;
}

.span-2-columns {
    grid-column: span 2;
}

.span-2-rows {
    grid-row: span 2;
}

.grid-2-columns {
    grid-template-columns: repeat(2, 1fr);   
}

.grid-sm-2-columns {
    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid-md-2-columns {
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid-auto-columns-max-content {
    grid-auto-columns: max-content;
}

.grid-auto-rows-max-content {
    grid-auto-rows: max-content;
}

.place-items-center {
    place-items: center;
}

.grid-column-100 {
    grid-template-columns: 100%;
}

.grid-column-max-content-1fr {
    grid-template-columns: max-content 1fr;
}
