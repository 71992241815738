@import "../abstracts/_palette.scss";
@import "../abstracts/_mixins.scss";

body {
    .btn {
        border: 0;
        padding: $button_padding_y $button_padding_x;
        color: $text_color_light;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:active,
        &.disabled,
        &:disabled {
            color: $text_color_light;
        }
    }

    .btn-primary {
        @include primary-button-color;

        &:hover {
            @include primary-button-hover-color;
        }

        &:focus {
            @include primary-button-color;
            @include primary-button-focus;
        }

        &:active {
            @include primary-button-hover-color;
        }

        &:disabled,
        &.disabled {
            @include primary-button-color;
        }
    }

    .btn-outline {
        @include link-text-color;
        border: 3px solid $white_label_primary_color;
        padding: calc($button_padding_y - 3px) calc($button_padding_x - 3px);
        background-color: transparent;

        &:hover {
            background-color: $neutral_20;
            @include link-text-color;
        }

        &:focus {
            @include link-text-color;
            @include primary-button-focus;
        }
    }

    .btn-ghost {
        border: 3px solid white;
        background-color: transparent;

        &:hover {
            @include link-text-color;
            background-color: white;
        }

        &:focus {
            box-shadow: $focus_box_shadow_size rgba(white, .5);
        }
    }

    .btn-icon {
        padding: .85rem;
        display: flex;
        font-size: 1.4rem;
        line-height: 1;

        i::before {
            vertical-align: unset;
            font-weight: bold !important;
        }
    }

    .btn-custom {

        &.facebook-icon {
            background-color: #1877F2;

            &::before {
                content: "";
                display: inline-block;
                @include facebook-white-icon;
                height: 1rem;
                width: 1rem;
                margin-right: 1.4rem;
                vertical-align: top;
            }

            &:hover {
                background-color: #166fe5;
                color: white;
            }

            &:focus {
                box-shadow: $focus_box_shadow_size rgba(#1877F2, 0.5);
            }
        }

        &.bankid-icon {
            background-color: #183E4F;

            &::before {
                content: "";
                display: inline-block;
                @include bankid-white-icon;
                height: 1rem;
                width: 1rem;
                margin-right: 1.4rem;
                vertical-align: top;
            }

            &:hover {
                background-color: #305261;
                color: white;
            }

            &:focus {
                box-shadow: $focus_box_shadow_size rgba(#183E4F, 0.5);
            }
        }

        &.google-icon {
            background-color: #ffffff;
            color: #3c4043;
            font-family: 'Roboto', sans-serif;

            &::before {
                content: "";
                display: inline-block;
                @include google-colored-icon;
                height: 1rem;
                width: 1rem;
                margin-right: 1.4rem;
                vertical-align: top;
            }

            &.google-calendar-icon::before {
                @include google-calendar-colored-icon;
            }

            &:hover {
                background-color: #F2F2F2;
                color: #3c4043;
            }

            &:focus {
                box-shadow: $focus_box_shadow_size rgba(white, 0.5);
            }
        }

        &.not-rounded {
            border-radius: 0;
        }

        &.text-not-transformed {
            text-transform: none;
        }

        &.category-button {
            @include category-color-dark;
            color: $text_color_dark;

            &:hover {
                @include category-color-hover;
            }

            &:focus {
                @include category-color-focus;
            }
        }
    }


    .btn-no-style {
        background-color: transparent;
        border: 0;
        color: $text_color_dark;

        &.disabled {
            opacity: .4;
        }

        &:hover {
            background-color: rgba(black, .05);
        }

        &:focus {
            @include primary-button-focus;
            outline: 2px transparent;
        }
    }

    .btn-linkalike {
        appearance: none;
        background-color: transparent;
        border: 0;
        padding: 0;
        text-align: start;
        @include link-text-color;
        font-weight: 600;

        &:hover,
        &:active {
            @include link-text-color-hover;
        }

        &:focus {
            @include link-text-color;
            @include primary-button-focus;
        }
    }

    .btn.btn-small {
        padding: $button_padding_y_small $button_padding_x_small;

        &.btn-icon {
            padding: .65rem;
            font-size: 1rem;
        }
    }

    .btn.btn-neutral {
        color: $text_color_dark;
        background-color: $neutral_20;

        &:hover {
            background-color: $neutral_40;
        }
    }

    .button-sizing {
        min-width: 30ch;
        max-width: min-content;
    }

    .button-sizing .btn {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        .button-sizing {
            min-width: 100%;
            max-width: none;
        }
    }

    .go-back-button:before {
        font-size: 1.1em;
        content: "\F284";
        font-weight: bold;
        font-family: bootstrap-icons;
        margin-left: -0.5rem;
        padding-right: .7em;
        vertical-align: bottom;
    }

    .burger {
        cursor: pointer;

        &.show {
            .burger-line-1 {
                transform: translate(0px, 5px) rotate(45deg);
                transition: transform .25s;
            }

            .burger-line-2 {
                transform: translate(0px, -4px) rotate(-45deg);
                transition: transform .25s;
            }
        }

        .burger-line-1 {
            width: 40px;
            height: 3px;
            background-color: black;
            margin: 6px 0;
            transition: transform .25s;
        }

        .burger-line-2 {
            width: 40px;
            height: 3px;
            background-color: black;
            margin: 6px 0;
            transition: transform .25s;
        }
    }

    .btn-close {
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }
    //when there are more than one button together, this is the spacing that should be used
    .buttons-gap {
        gap: $gap_default;
    }

    .btn-group .btn:not(.dropdown-toggle) {
        border-radius: 0;

        &:first-child {
            border-top-left-radius: $button_border_radius;
            border-bottom-left-radius: $button_border_radius;
            padding-left: $button_padding_x;
        }

        &:last-child {
            border-top-right-radius: $button_border_radius;
            border-bottom-right-radius: $button_border_radius;
            padding-right: $button_padding_x;
        }
    }
}
