@import "../../abstracts/_palette.scss";
@import "../../abstracts/_variables.scss";

body {

    .map-list-button {
        font-size: 1rem;
        line-height: 144%;
        font-weight: 500;
        color: $text_color_light;
    }

    .supplier-event-tabs-container {

        &.nav-tabs {
            border-bottom: 3px solid $boka_primary_color;
        }

        &.nav-tabs .nav-link {
            color: $primary_120;

            &:hover {
                border-color: rgba($boka_primary_color,.3);
            }
        }

        &.nav-tabs .nav-link.active {
            border: 1px solid $boka_primary_color;
            background-color: $boka_primary_color;
            color: white;
        }
    }
}
