@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_variables.scss";

.booking-info-modal {
    .grid-md-row-1 {
        @include media-breakpoint-up(md) {
            grid-row: 1;
        }
    }

    .span-md-2-columns {
        @include media-breakpoint-up(md) {
            grid-column: span 2;
        }
    }

    .height-fit-content {
        height: fit-content;
    }

    .modal-footer {
        border: 0;
        grid-auto-columns: 100%;

        .btn-linkalike {
            padding: $button_padding_y $button_padding_x;
        }
    }
}
