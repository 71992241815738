body {

    .dropdown-menu {
        padding-top: 1.1em;
        padding-bottom: 1.2em;
        border-radius: 0;
        border: none;
        border-top-left-radius: 1.3em;
        border-bottom-right-radius: 1.3em;
        min-width: 13rem;

        &.dropdown-small {
            min-width: 7.5rem;
        }

        &.upcoming-bookings-dropdown {
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;
            padding-top: .6em;
            padding-bottom: .6em;

            li {
                padding-bottom: 0.2rem;
            }

            max-height: 15em;
            overflow-y: scroll;
        }
    }

    .dropdown-menu-reversed {
        border-radius: 0;
        border-top-right-radius: 1.3em;
        border-bottom-left-radius: 1.3em;
    }

    .dropdown-toggle {
        &:after {
            border: none;
            content: '\f282';
            font-family: bootstrap-icons;
            font-size: 0.89rem;
            font-weight: 600;
            vertical-align: bottom;
        }

        &.noChevron {
            &:after {
                content: none;
                display: none;
            }
        }
    }

    .bs-3 .dropdown-menu {
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 4px;
        padding: 4px 0;
    }
}
