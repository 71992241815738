@import "_articleMenu.scss";
@import "_calendarToolbar.scss";
@import "_contextMenu.scss";
@import "_calendarView.scss";
@import "_calendarControls.scss";
@import "_calendarModals.scss";
@import "_bookingInfoModal.scss";

.bs-3 .btn.old-button-override {
    border-radius: 5rem;
    padding: $button_padding_y $button_padding_x;
    line-height: 129% !important;
    height: auto;
}


.calendar-color {
    background-color: var(--calendar-color, $boka_primary_color);
    color: var(--calendar-text-color, $text_color_light);
}


.resource-info-container {
    flex-direction: row !important;
    gap: 1rem;
}
