@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_mixins.scss";
@import "../../abstracts/_variables.scss";


.opening-hours {
    tbody th,
    td {
        border-bottom: 1px solid #000;
    }
}


.image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
    grid-auto-rows: 1fr;

    .show-all-button {
        @include link-text-color;
        background-color: $background_neutral_light;
    }

    picture {
        max-width: 21rem;
    }
}

.resource {

    gap: 5%;

    .resource-image,
    .resource-initial {        
        width: clamp(5rem, 30vw, 25%);
        aspect-ratio: 1 / 1;
        align-self: start;
    }

    .resource-image img {
        width: 100%;
        height: auto;
    }

    .resource-initial {
        font-size: clamp(1.56rem, 1.3rem + 3vw, 2.56rem);
        place-content: center;
    }

    .resource-text {
        align-self: center;
    }
}
