body {
    #setting-wrapper #resource-settings {
        #resource-description, #resource-internal-notes {
            border: 1px solid rgba(0,0,0,0.2);
        }
    }

    #alertsettings-complementtext, #alertsettings-reminder-compelementtext {
        border: 1px solid rgba(0,0,0,0.2);
    }

    .label-lookalike {
        font-size: 0.88rem;
        font-weight: 700;
        margin-bottom: 5px;

    }
}
