@import "../abstracts/_variables.scss";
@import "../abstracts/_palette.scss";
@import "../abstracts/_mixins.scss";
@import "../abstracts/_breakpoints.scss";

body {
    .search-box {
        border-radius: 0;
        border: none;
        border-top-right-radius: 1.3em;
        border-bottom-left-radius: 1.3em;
        box-shadow: 0 .2rem .35rem 0 rgba(0, 0, 0, 0.16);
    }

    .form-control:focus.category-focus {
        @include category-color-focus;
    }

    .background-secondary .form-control:focus.category-focus {
        box-shadow: 0 0 0 0.25rem rgba($secondary_100, .25);
    }

    .form-select:focus.category-focus {
        @include category-color-focus;
    }

    .background-secondary .form-select:focus.category-focus {
        box-shadow: 0 0 0 0.25rem rgba($secondary_100, .25);
    }

    .search-box .form-check-input {
        &[type="checkbox"] {
            &:checked {
                @include category-color-dark;
            }
        }

        &:focus {
            @include category-color-focus;
        }
    }

    .search-form-icon {
        @include category-color-extra-light;
        height: $input-height;
        display: none;
        margin-left: calc(var(--bs-gutter-x) * 0.5);

        &:before {
            vertical-align: -50%;
        }
    }

    @media screen and (max-width: 767px) {

        .search-form-icon {
            display: flex;
        }
        //override bootstrap in mobile devices
        .search-box .form-floating {
            position: static;
            padding-left: 0;

            .form-control {
                height: $input-height;
                line-height: $input-line-height;
                padding: $input-padding-y $input-padding-x;

                &:focus,
                &:not(:placeholder-shown) {
                    padding: $input-padding-y $input-padding-x;
                }

                &::placeholder {
                    color: $input-placeholder-color;
                    opacity: 1;
                }
            }

            label {
                display: none;
            }
        }
    }
    //auto complete input styling
    .form-floating:has( .form-control:focus) label,
    .form-floating:has( .form-control:not(:placeholder-shown)) label {
        opacity: .65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }

    .xdsoft_autocomplete {
        background-color: $background_color_light;
        position: relative !important;
    }

    .form-floating .xdsoft_autocomplete_hint {
        padding-top: .375rem !important;
        padding-bottom: .375rem !important;
        background-color: transparent !important;

        @include media-breakpoint-up(md) {
            padding-top: 1.625rem !important;
        padding-bottom: 0.625rem !important;
        }
    }

    .xdsoft_autocomplete_dropdown {
        overflow: hidden auto !important;
        padding: .5rem;
        border-radius: $container_border_radius_medium;
        max-height: 75vh !important;

        div {
            padding: .3rem .6rem !important;
            border-radius: $container_border_radius_small;
            gap: 0 1rem;
        }

        .active {
            background-color: $background_neutral_light !important;
        }

        .search-item-title {
            flex: 0 0 auto;
            max-width: calc(100% - 2rem);
            color: $text_color_dark;
        }

        span {
            color: $text_color_neutral;
        }

        span:last-of-type {
            flex: 1 0 0;
        }

        @include media-breakpoint-down(sm) {
            .search-item-title {
                min-width: calc(100% - 2rem);
            }
        }
    }
}
