body {
    .spinner-wrapper {
        inset: 0;
        z-index: 1051;

        .spinner {
            z-index: 1052;
        }
    }

    .spinnerBackdrop {
        background-color: rgba(#000, .5);
        
        .spinner-border {
            padding: 3rem;
        }
    }
}
