@import "../../abstracts/_variables.scss";
@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_mixins.scss";



.bs-3 .modal-box {
    border-radius: .3rem;
}

.bs-3 .modal-content ,
.bs-3 .modal-content > div {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.bs-3 .modal-content .modal-header {
    border: 0;
    padding-bottom: 0;
    flex-direction: row;

    .modal-title,
    h2,
    h3,
    h4 {
        margin-block: 0;
    }

    .fa {
        display: inline-flex;
        align-self: center;
    }

    > :first-child {
        max-width: calc(100% - 2rem);
    }
}

.bs-3 .modal-body {
    max-height: 100%;
    overflow: hidden auto;
    margin-right: .5rem;
    padding-right: .5rem;

    &.row {
        display: flex;
        flex-direction: column;
    }

    //scrollbar in modal
    @include firefox-scroller-colors;
    scrollbar-width: thin;
    //Other browsers
    //Width
    &::-webkit-scrollbar {
        width: .6rem;
        height: .6rem;
    }
    //Track
    &::-webkit-scrollbar-track {
        background: $neutral_10;
        border-radius: .3rem;
    }
    //Handle
    &::-webkit-scrollbar-thumb {
        @include primary-button-color;
        border-radius: .3rem;
    }
    //Handle on hover
    &::-webkit-scrollbar-thumb:hover {
        @include primary-button-hover-color;
    }
}

.modal .modal-footer {
    border: 0;

    button {
        margin: 0;
        border-radius: $button_border_radius;
        padding: $button_padding_y $button_padding_x;
        line-height: normal;
    }
}

.modal .modal-dialog .modal-footer.buttons-gap {
    flex-direction: row;
}

