@import "../abstracts/_palette.scss";

body .bs-3 {

    .color-picker-button {
        width: 15rem;
        height: 2.2rem;
        color: $neutral_140;
        line-height: normal;
        padding-inline: .6rem;
        text-align: start;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
        background-size: 16px 10px;
    }

    .color-picker-dropdown {
        width: 15rem;
        color: $neutral_140;
        padding-block: .3rem;
        margin-top: -3px !important;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.16);
    }

    .color-picker-item {
        pointer-events: all;
        cursor: pointer;
        padding-inline: .6rem;

        &:hover {
            background-color: $neutral_10;
        }

        .color-thumbnail {
            width: 1.3rem;
            height: 1.3rem;
            margin-block: .2rem;
            vertical-align: middle;
        }
    }

    .color-thumbnail {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: 0;
        border-radius: 50%;
        vertical-align: sub;
        margin-right: .5rem;
    }
}
