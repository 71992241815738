@import "../abstracts/_variables.scss";
@import "../abstracts/_breakpoints.scss";

.bs-3 {
    //toolbar
    .fc-toolbar {
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-around;
        background-color: var(--calendar-color, $boka_primary_color);

        &.fc-header-toolbar {
            margin-bottom: 0;
        }


        .fc-center {
            flex: 1 0 100%;
            gap: .8rem;
            justify-self: center;
            width: auto;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;

            h2 {
                margin: 0;
                order: unset;
                color: var(--calendar-text-color, $text_color_light);
                flex-grow: 1;
                max-width: min(30ch, 100% - 4rem);
                font-weight: 300;
                font-size: 1.1rem;
            }

            button.fc-next-button,
            button.fc-prev-button {
                order: unset;
                padding: .5rem;
                display: flex;
                font-size: 1rem;
                line-height: 1rem;

                span {
                    width: 1rem;
                    text-align: center;
                }
            }
        }
    }

    .fc-left,
    .fc-right {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: nowrap;
        width: auto;
        float: unset;
        gap: .6rem;

        @include media-breakpoint-up(sm) {
            gap: $gap_default;
        }
    }

    .fc-clear {
        display: none;
    }

    .fc .fc-toolbar button {
        margin: 0;
        background-color: $background_color_light;
        color: $text_color_neutral_dark;
        border-radius: $button_border_radius;
        height: unset;
        line-height: unset;
        padding: $button_padding_y_small $button_padding_x_small;

        &.active {
            background-color: $neutral_20;
            color: $text_color_dark;
            box-shadow: none;
        }

        &:hover {
            background-color: $neutral_40;
            color: $text_color_dark;
        }

        &:focus {
            box-shadow: $focus_box_shadow_size rgba(white, .5);
        }
    }
    //calendar
    .fc table {
        font-size: .78rem;
    }

    .fc-head {
        font-size: .89rem;

        th {
            text-align: center;
        }
    }

    .fc-body .fc-axis {
        font-size: .7rem;
    }

    .fc-resource-cell {
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
    }

    .fc-day-header:not(:last-child),
    .fc-resource-cell.last-resource-per-day:not(:last-child),
    .fc-bg .last-resource-per-day:not(:last-child),
    .fc-axis {
        border-right-width: 2px !important;
    }

    .fc-today.alert-info {
        opacity: .5;
    }

    .fc-highlight {
        background-color: $boka_primary_color;
        opacity: .5;
    }

    .fc-nonbusiness {
        background-color: transparent !important;
        background-image: repeating-linear-gradient(135deg, $neutral_60 0px, $neutral_60 2px, transparent 2px, transparent 6px);
        height: unset;
    }

    .fc-bgevent {
        opacity: .17;
    }

    .fc-time-grid .fc-slats {
        td {
            border-top: 1px solid $neutral_80;
            height: 2em;
        }

        .fc-minor td {
            border-top: 1px dotted $neutral_80;
        }
    }

    .fc-event {
        padding-inline: .3rem;
        cursor: pointer;
    }

    .fc-content {
        font-size: .68rem;

        .fc-title {
            font-size: .75rem;
        }
    }

    .fc-day-number {
        font-size: .7rem;
    }

    .fc-today .fc-day-number {
        font-size: 1rem;
    }

    .fc-week-number {
        font-size: .89rem;
    }

    .fc-event-dot {
        border-radius: 50%;
    }
}
