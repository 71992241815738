@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

body {
    //text colors
    .text-color-neutral {
        color: $neutral_110;
    }

    .text-color-light {
        color: $text_color_light;
    }

    .text-color-primary {
        @include link-text-color;
    }
    .text-color-primary-dark {
        @include link-text-color-hover;
    }
    //background colors
    .background-primary-light {
        @include primary-bg-light;
    }

    .background-primary {
        background-color: $background_primary;
    }

    .background-primary-dark {
        background-color: $background_primary_dark;
    }

    .background-secondary-light {
        background-color: $background_secondary_light;
    }

    .background-secondary {
        background-color: $background_secondary;
    }

    .background-neutral {
        background-color: $background_neutral;
    }
    
    .background-info {
        background-color: $info_20;
    }

    .background-neutral-light {
        background-color: $background_neutral_light;
    }
    //category colors
    .category-color-default {
        @include category-color-default;
    }

    .category-color-light {
        @include category-color-light;
    }

    .category-color-dark {
        @include category-color-dark;
    }

    .category-focus-color {
        &:focus {
            @include category-color-focus;
        }
    }
    //border colors
    .border-neutral-30 {
        border-color: $neutral_30;
    }
}

