@import "../../abstracts/_variables.scss";
@import "../../abstracts/_mixins.scss";

#booking-dialog {
    .modal-body {
        padding: 0;
        overflow-x: hidden;
    }

    #endtime {
        @media (max-width: 425px) {
            background-image: none;
        }
    }

    #starttime {
        @media (max-width: 425px) {
            background-image: none;
        }
    }

    .modal-body {
        display: grid;


        button {
            width: 100%;
        }
    }

    select,
    .chosen-single {
        @include chevron-background-image();
    }

    input {
        box-shadow: none;
    }

    .checkbox {
        margin: 0;
    }

    .selectize-input {
        border: 0;
        background-color: transparent;
    }

    .btn-cancel-container .btn-cancel {
        width: calc(100% - 1.8rem);
        margin-inline: .85rem;
    }

    .input-group.mobile-date > input {
        line-height: 1.5;
    }
}
