@import "../../abstracts/_variables.scss";
@import "../../abstracts/_breakpoints.scss";

.calendar-container {

    .fc-scroller.fc-time-grid-container {
        overflow: hidden !important;
    }

    .table-bordered {
        border: 0;

        tr,
        th,
        td {
            border: 0;
        }

        thead .table-bordered thead {
            border: 0;

            th {
                font-weight: 600;
            }

            th,
            td {
                border-top: 0;
                vertical-align: middle;
            }
        }

        .fc-bg td,
        thead thead th {
            border: 1px solid $neutral_80;
            background-clip: padding-box;
        }
    }

    .fc-time-grid .fc-time {
        white-space: normal;

        > span {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            white-space: nowrap;
        }
    }

    .fc-time-grid .fc-title {
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
    }

    .fc-month-view .table-bordered {

        .fc-day-header span span {
            vertical-align: bottom;
        }

        .fc-resource-cell,
        thead :nth-child(7n+2),
        .fc-day:nth-child(7n+2) {
            border-left-width: 3px;
        }

        thead :nth-child(2),
        .fc-day:nth-child(2) {
            border-left-width: 1px;
        }

        .fc-day-top {
            padding-inline: .5rem;
        }

        .fc-day-top.fc-today {
            padding: 0 .5rem;
            font-size: 1rem;
        }

        .fc-content-skeleton td {
            border: 0;
        }

        .fc-disabled-day {
            background-color: $neutral_10;
        }
    }

    .fc-responsiveMonth-view {

        .fc-today {
            padding: 0;
            font-weight: 600;
            line-height: 1.1;
        }

        .fc-hasEvent {
            text-align: center;
            vertical-align: middle;
        }

        .fc-myevent .fc-event-dot {
            box-shadow: 0 0 0 .2rem var(--calendar-color, #0A9398);
        }

        .fc-more {
            display: none;
        }

        .fc-highlight {
            opacity: .15;
        }

        .fc-day-grid-event-container {
            border: 0;
            table-layout: auto;

            tr td {
                padding: .5rem;
                vertical-align: middle;
                border: 0;
                white-space: nowrap;
                border-bottom: 1px solid $neutral_30;

                &:first-child,
                &:nth-child(2) {
                    width: 1%;
                    text-align: center;
                }

                &:last-child {
                    position: relative;
                    width: 50%;

                    span {
                        position: absolute;
                        inset: .5rem;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .fc-day-header {
        padding-block: .2rem;
    }

    .fc-resource-cell {
        padding-bottom: .5rem;
    }

    .fc-resource-line {
        border-bottom: .3rem solid;
        padding-bottom: .1rem;
        margin: 0 0 .2rem;
    }

    .fc-timeline .fc-time-area .fc-rows td {
        border-bottom: 1px solid #ddd;
    }

    .fc-list-table {
        margin-bottom: 0;

        td {
            vertical-align: middle;
        }
    }

    .fc-cell-content + .fc-resource-line {
        flex-grow: 1;
        padding: 0;
        margin-inline: .5rem 1rem;
        flex-grow: 1;
        height: 50%;
    }

    .fc-list-item {
        cursor: pointer;
    }

    .fc-list-item-time,
    .fc-list-item-marker {
        width: 1%;
    }

    .fc-list-item-title,
    .fc-list-event-name:not(:last-child),
    .fc-list-bookers-name:not(:last-child) {
        width: 10%;
        padding-right: 2rem;
        white-space: nowrap;
    }

    .fc-list-item-marker {
        text-align: center;
    }

    .fc-list-empty {
        font-size: .89rem;
        font-weight: 700;
        background-color: rgba(0, 0, 0, 0.1);
        padding: .4rem;
    }

    @include media-breakpoint-down(md) {
        .fc-list-item-title,
        .fc-list-event-name {
            padding-right: .5rem;
        }

        .fc-list-item td:last-child {
            position: relative;
            width: 50%;

            span {
                position: absolute;
                inset: 8px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }


    @include media-breakpoint-down(sm) {
        .fc-list-item-title ~ .hide-on-mobile {
            display: none;
        }

        .fc-list-item-title ~ td:nth-last-child(2) {
            position: relative;
            width: 40%;

            span {
                position: absolute;
                inset: 8px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .fc-timeline .fc-event-container {
        margin-inline: 2px 4px;
        height: 100% !important;
        padding: 0;

        .fc-timeline-event {
            height: calc(100% - .1rem);
            padding: 0;
            border-radius: .2rem;
        }
    }

    .fc-agenda-view .fc-now-indicator-line {
        width: 200vw;
        transform: translateX(-50%);
    }

    .holiday-description {
        color: $danger;
    }

    @media print {
        .fc-left,
        .fc-right,
        .fc-now-indicator {
            display: none !important;
        }

        .fc-resource-line {
            border: 0 !important;
        }
    }
}

.fc-now-indicator {
    border-color: $coral_100;
}
