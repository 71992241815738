// Läs här hur man kan customizea: https://getbootstrap.com/docs/5.1/customize/sass/
@import "../../abstracts/_palette.scss";
@import "../../abstracts/_variables.scss";

//our own variables
$primary: $boka_primary_color;
$secondary: $secondary_115;
$danger: $danger;
$warning: $warning;
$success: $success;
$info: $info;
$btn-padding-x: $button_padding_x;
$btn-padding-y: $button_padding_y;
$btn-border-radius: $button_border_radius;
$font-family-sans-serif: $font_family;
$font-family-base: $font_family;
$navbar-light-color: $navbar_bg_color;
$navbar-light-hover-color: $navbar_text_hover_color;
$container-max-widths: $container_max_widths;
$box-shadow-sm: $shadow_small;
$box-shadow: $shadow_medium;
$link-decoration: none;
$form-check-input-border-radius: 0;
$input-border-radius: 0;
$input-border-color: $input_border_color;
$input-focus-border-color: $input-border-color;
$btn-disabled-opacity: $disabled_opacity;
$border-color: $neutral_40;
$border-radius: $container_border_radius_medium;
$border-radius-sm: $container_border_radius_small;
$border-radius-lg: $container_border_radius_large;
    
@import "node_modules/bootstrap/scss/_functions.scss";

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/_root.scss";

//for media-queries
@import "node_modules/bootstrap/scss/mixins/breakpoints";


:not(.bs-3) {
    @import "node_modules/bootstrap/scss/bootstrap";
}
