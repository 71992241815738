body {
    #articleCategoryList_wrapper, 
    #articleList_wrapper, 
    #groupArticleList_wrapper, 
    #additionalServiceList_wrapper {

        .form-control.input-sm {
            line-height: normal;
        }

    }
}
