@import "../abstracts/_variables.scss";
@import "../abstracts/_breakpoints.scss";

body {
    .table-admin {
        width: 100%;

        th,
        td {
            padding: .5rem 1rem;
            white-space: nowrap;
        }

        thead tr {
            box-shadow: $shadow_small;
            border-radius: $container_border_radius_small;
        }

        th:first-child,
        td:first-child {
            border-start-start-radius: $container_border_radius_small;
            border-end-start-radius: $container_border_radius_small;
        }

        th:last-child,
        td:last-child {
            border-start-end-radius: $container_border_radius_small;
            border-end-end-radius: $container_border_radius_small;
        }

        thead th {
            background-color: $neutral_20;
            color: $text_color_dark;
            font-weight: 600;
        }

        tbody tr:first-of-type td {
            padding-top: .8rem;
        }
        
        tbody tr:nth-child(even) td {
            background-color: $neutral_5;
        }
    }

}
