@import "../abstracts/_variables.scss";

body {
    .accordion-button {
        background-color: white;
        border-radius: 0;
        box-shadow: 0 -3px 0 $boka_primary_color inset;

        &:not(.collapsed) {
            background-color: white;
            box-shadow: none
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem $boka_primary_color;
        }
    }

    .accordion-item {
        background-color: white;
        border: 0;

        &:first-of-type {
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            .accordion-button {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }

        &:last-of-type {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            .accordion-button {
                &.collapsed {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            .accordion-collapse {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .accordion-item br {
        margin-bottom: .7em;
    }

    .accordion-item li {
        padding-bottom: .5em;
    }

    .accordion-body {
        box-shadow: 0 -3px 0 $boka_primary_color inset;
    }
}
