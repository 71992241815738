body {

    .about-page-image {
        width: 100%;
        height: auto;
        aspect-ratio: 12 / 10;
        object-fit: cover;
    }

    .hero-margins-top-bottom {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .hr-margins-top-bottom {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    @media screen and (max-width: 992px) {
        .hr-margins-top-bottom {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
}
