@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

body {
    .rounded-corners-top-right-bottom-left {
        border-radius: 0;
        border-top-right-radius: 1.3em;
        border-bottom-left-radius: 1.3em;
        border: none;
    }

    .container-neutral {
        padding: 1.5rem 1rem;
        background-color: $background_neutral_light;
        border-radius: .5rem;
        box-shadow: $shadow_small;
    }

    .alert-primary {
        color: var(--custom-color-130, $primary_130);
        @include primary-bg-extra-light;
        border-color: var(--custom-color-30, $primary_30);
    }
}
