@import "../abstracts/_palette.scss";
@import "../abstracts/_variables.scss";

body {
    .form-select {
        border-radius: 0;
        border: 1px solid $input_border_color;
        //to make the border outside the white background
        border-image: linear-gradient($input_border_color, $input_border_color) 1;
        border-image-outset: 1;
    }

    option {
        font-family: $font_family;
    }

    .country-code {
        &.form-select {
            background-color: #E9ECEF;
            padding: 0.375rem 0.75rem;
            width: 7.2ch;
        }
    }

    .select-border-bottom {
        border: 0;
        border-image: none;
        border-bottom: 3px solid $boka_primary_color;
        background-color: transparent;

        &:focus {
            border-color: $boka_primary_color;
        }
    }
}
