@import "../abstracts/_mixins.scss";

body {
    @include body-background-color;

    grid-template-rows: max-content 1fr max-content;

    :focus-visible {
        outline: 2px solid $white_label_primary_color;
    }

    .btn:focus,
    .btn:focus-visible,
    input:focus,
    input:focus-visible {
        outline: 2px transparent !important;
    }

    .btn *:focus {
        outline: none;
    }
    //workaround to get similar focus effect that uses box-shadow when it is already in use
    .shadow:focus {
        outline: .25rem solid rgba(var(--button-color-rgb, $primary_100_rgb), .5);
    }

    img {
        content-visibility: auto;
    }

    h1:focus-visible {
        outline: none;
    }
}
