@import "../abstracts/_palette.scss";

body {
    #news-container {

        .news.media {
            background-color: $primary_10;
            margin: 1rem 0;
            padding: .25rem .75rem;

            .news-close {
                display: table-cell;
                color: black;
            }

            .media-left.media-middle {
                
                font-size: 1.25rem;
            }

            .media-body.media-middle {

                ul {
                    list-style-type: none;
                }
            }
        }
    }
}
