body {
    #step-one-cards-id, #step-two-cards, #int-step-fore-cards, #ext-step-fore-cards, #step-fore-cards, #summary-block {
        .w-clearfix {
            .card-header {
                font-size: .45em;
                background-color: transparent;
                border-bottom: none;
                padding: 0.5rem 0;
            }
        }
    }

    #step-one-cards-id .col {
        min-width: 250px;

        &.step-card-free button.btn {
            background-color: rgba(lightgray, .6);
            color: rgba(black, .6);
        }
    }

    #customerInfo {
        border: 2px solid gray;
        padding: 15px;

        .col-xs-3 {
            color: rgb(10, 147, 152);
        }
    }

    #payment-paymentForm {
        a.paymentShowInfo {
            cursor: pointer;
        }
    }

    .paymentMethodTypeImageSpan.hidden-xs {
        padding-left: 20px;
    }

    #paymentMethodTable tbody tr.selected {
        background-color: #e9f0ff;
    }

    #store-selection-div {
        .col-xs-12 {
            position: static;

            &.store-flex-button-div {
                position: absolute;
            }
        }
    }

    p.step-card-text {
        margin-bottom: 55px;
    }
}
