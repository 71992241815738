@import "../../abstracts/_variables.scss";

.event-grid {
    grid-template-columns: repeat(auto-fill, minmax(min(17rem, 100%), 1fr));
}

.event {
    grid-template-columns: 1fr max-content;
    gap: .5rem 1rem;
    cursor: pointer;
    transition: background-color $animation_transition;

    &:hover {
        background-color: $background_neutral_light;
    }

    .event-picture {
        grid-row: 1;
    }

    img {
        aspect-ratio: 2 / 1;
    }

    button {
        place-self: center;
    }

    .event-info {
        grid-template-columns: max-content 1fr;
        gap: 0 1rem;
    }
}
