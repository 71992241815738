@import "../abstracts/_palette.scss";
@import "../abstracts/_variables.scss";
@import "../abstracts/_breakpoints.scss";
@import "../abstracts/_mixins.scss";

#CybotCookiebotDialog {
    --accent-darker-color: rgba(0, 0, 0, .3);
    border-radius: 0;
    font-size: .89rem;
    width: calc(100% - 1.5rem);
    padding: 1.5rem;
    box-shadow: $shadow_medium;
    color: $text_color_light;
    @include primary-button-color;
    font-family: $font_family;

    .CybotCookiebotDialogContentWrapper {
        gap: 1rem;
    }

    #CybotCookiebotDialogBodyContent {
        padding: 0 .5rem 0 0 !important;
    }

    h2 {
        font-size: 1rem;
        font-family: $font_family !important;
    }

    #CybotCookiebotDialogBodyEdgeMoreDetails {
        margin: .5rem 0.2rem .5rem 0 !important;

        a {
            color: $text_color_light !important;

            &:hover {
                opacity: .8;
            }
        }

        a::after {
            border-color: $text_color_light !important;
            margin-bottom: .2rem;
        }
    }

    .CybotCookiebotScrollContainer,
    #CybotCookiebotDialogHeader {
        border: 0 !important;
        padding: 0;
    }

    .CybotCookiebotFader {
        left: 0;
        width: calc(100% - .5em) !important;
        background-image: linear-gradient(to top, rgb(var(--button-color-rgb, $primary_115_rgb)), rgba(var(--button-color-rgb, $primary_115_rgb), .8), rgba(var(--button-color-rgb, $primary_115_rgb), 0)) !important;
    }

    #CybotCookiebotDialogPoweredbyLink {
        display: none;
    }

    #CybotCookiebotDialogFooter {
        padding: 0 !important;
    }

    #CybotCookiebotDialogBodyButtonsWrapper {
        gap: $gap_default;
    }

    //buttons
    button {
        font-size: 0.78rem;
        line-height: 129%;
        text-transform: uppercase;
        font-weight: 700;
        color: $boka_light_color;
        border: 3px solid $boka_light_color !important;
        padding: calc($button_padding_y - 3px) calc($button_padding_x - 3px);
        border-radius: 5rem;
        @include primary-button-color;
        margin: 0 !important;
        width: auto !important;
        white-space: nowrap;
    }

    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
        @include link-text-color;
        background-color: $boka_light_color;
    }

    #CybotCookiebotDialogBodyLevelButtonCustomize,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
        background-color: transparent;
    }


    .CybotCookiebotDialogArrow {
        vertical-align: 0.15rem !important;
    }

    #CybotCookiebotDialogPoweredByText a {
        color: $text_color_light;
    }

    


    //customize cookies
    #CybotCookiebotDialogNav {
        border: 0;

        .CybotCookiebotDialogNavItemLink {
            border-bottom: 3px solid var(--accent-darker-color);

            &.CybotCookiebotDialogActive {
                border-bottom: 3px solid $boka_light_color;
            }
        }
    }

    .CybotCookiebotDialogActive * {
        border-color: var(--accent-darker-color);
    }

    .CybotCookiebotDialogShow:first-of-type::before {
        border-color: var(--accent-darker-color) !important;
    }

    .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: var(--accent-darker-color) !important;

        &::before {
            background-color: rgba(255, 255, 255, .7);
        }
    }

    .CybotCookiebotDialogDetailBulkConsentCount {
        background-color: var(--accent-darker-color);
    }

    #CybotCookiebotDialogTabContent input:focus + .CybotCookiebotDialogBodyLevelButtonSlider {
         box-shadow: none !important;
         outline: 2px solid rgba($boka_light_color, .3);
    }

    .CookieCard {
        border-bottom: 1px solid var(--accent-darker-color);
    }

    .CybotExternalLinkArrow {
        filter: invert(100%);
    }

    #CybotCookiebotDialogHeader {
        display: none;
    }
    .CybotCookiebotScrollbarContainer {
        background-color: transparent !important;
    }

    @include media-breakpoint-up(lg) {

        &.CybotEdge {
            top: unset !important;
            bottom: 1rem;
            transform: translate(-50%) !important;
            width: 101% !important;
            max-width: 101% !important;
            padding: 3rem 2rem !important;

            .CybotCookiebotDialogContentWrapper {
                flex-direction: row;
                margin: 0 auto;
                position: relative;
                max-width: 1202px !important;
                align-items: center;
                gap: 1rem;
                padding-inline: .75rem;
            }

            #CybotCookiebotDialogHeader {
                width: 0 !important;
                position: relative;
                align-self: stretch;
                order: 5;

                #CybotCookiebotDialogHeaderLogosWrapper {
                    position: absolute;
                    right: 1rem;
                    bottom: 0;
                    align-items: end;

                    svg {
                        display: block;
                    }
                }
            }

            #CybotCookiebotDialogBodyButtonsWrapper {
                flex-direction: column-reverse;
            }

            #CybotCookiebotDialogFooter {
                width: auto !important;
                order: 6;
            }
        }

        #CybotCookiebotDialogBody * {
            padding: 0 !important;
        }

        .CybotCookiebotDialogBodyBottomWrapper {
            margin-top: .5rem !important;
        }

        #CybotCookiebotDialogBodyEdgeMoreDetails {
            margin: 0 !important;
            justify-content: unset !important;
        }

        #CybotCookiebotDialogHeader {
            order: 7;

            #CybotCookiebotDialogHeaderLogosWrapper {
                justify-content: center;
            }

            svg {
                display: block;
            }
        }

        #CybotCookiebotDialogTabContent {
            margin: 0 !important;
        }

        #CybotCookiebotDialogBodyContent {
            padding: 0 .5rem 0 0 !important;
        }

        #CybotCookiebotDialogPoweredByText {
            margin-top: 1em;
        }

        #CybotCookiebotDialogPoweredByText {
            display: none;
        }

        #CybotCookiebotDialogHeader {
            display: block;
        }
    }
}

#CybotCookiebotDialog,
.CybotCookiebotScrollArea {
    //scrollbar
    scrollbar-color: $boka_light_color transparent !important;
    //track
    &::-webkit-scrollbar-track {
        background: transparent !important;
    }
    //Handle
    &::-webkit-scrollbar-thumb {
        background-color: $boka_light_color !important;
    }
    //Handle on hover
    &::-webkit-scrollbar-thumb:hover {
        opacity: .8;
    }
}

//cookie bubble
#CookiebotWidget {
    display: none;
    /*left: unset !important;
    right: 1.1rem;
    bottom: 5rem !important;

    &.CookiebotWidget-open {
        max-width: calc(100% - 3rem) !important;
    }

    .CookiebotWidget-logo svg circle {
        fill: $white_label_primary_color;
    }

    .CookiebotWidget-logo svg path {
        fill: $boka_light_color;
    }

    * {
        font-family: $font_family;
    }

    svg {
        fill: $white_label_primary_color !important;
    }

    .CookiebotWidget-show-consent-details {
        @include link-text-color;
    }

    #CookiebotWidget-buttons {
        display: flex;
        flex-wrap: wrap-reverse;
        gap: $gap_default;

        button {
            flex-grow: 1;
            font-size: 0.78rem;
            line-height: 129%;
            text-transform: uppercase;
            font-weight: 700;
            color: $boka_light_color;
            border: 3px solid $white_label_primary_color !important;
            padding: calc($button_padding_y - 3px) calc($button_padding_x - 3px);
            border-radius: 5rem;
            margin: 0 !important;
            width: auto !important;
            white-space: nowrap;

            &#CookiebotWidget-btn-withdraw {
                @include link-text-color;
            }

            &#CookiebotWidget-btn-change {
                @include primary-button-color;
            }
        }
    }

    @include media-breakpoint-up(md) {
        left: 1rem !important;
        right: unset;
        bottom: 1rem !important;
    }*/
}
