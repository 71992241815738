@import "../abstracts/_palette.scss";
@import "../abstracts/_breakpoints.scss";

@import "../abstracts/_mixins.scss";
@import "../abstracts/_variables.scss";


.form-grid {
    grid-template-columns: 100%;
    gap: $form_gap;
    grid-auto-rows: max-content;

    @include media-breakpoint-up(sm) {
        grid-template-columns: 45% 1fr;

    }

    .span-2-columns {
        @include media-breakpoint-down(sm) {
            grid-column: auto;
        }
    }
}

.form-gap {
    gap: $form_gap;
}

body {
    .form-label {
        font-weight: 500;
        margin-bottom: 0;
        gap: .4em 1em;
        grid-auto-rows: max-content;

        input:focus,
        select:focus, 
        textarea:focus {
            @include primary-button-focus;
        }
    }

    label:has( .validation-message ) .form-control,
    label:has( .validation-message ) .form-check-input {
        border-color: $danger;
    }

    .validation-message {
        font-size: max(.89em, 14px);
        font-weight: 400;
    }

    .required span:first-of-type::after {
        content: "*";
        color: $danger;
        margin-left: .5em;
    }

    .align-with-checkbox-label {
        margin-left: max(2rem, 2em);
    }
}
