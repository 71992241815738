@import "../abstracts/_palette.scss";
@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";
@import "../abstracts/_breakpoints.scss";

body {

    .my-page-link-in-my-page {
        font-size: 0.78rem;
        line-height: 129%;
        font-weight: 600;
    }

    .navbar-set-height {
        height: $navbar_height_rem;
    }

    .below-navbar {
        margin-top: $navbar_height_rem;
    }

    .navbar-style {
        background-color: white;
        box-shadow: 0 0.15rem 0.4rem 0 rgba(0, 0, 0, 0.16);
    }

    .navbar-style .navbar-nav .nav-link.special {
        color: $secondary_115;
        padding: .55rem 1.4rem;
        border-radius: 50em;
        text-align: center;
        white-space: nowrap;

        @keyframes specialGrow {
            from {
                padding: .2rem 1.4rem;
            }

            to {
                padding: .55rem 1.4rem;
            }
        }

        &:hover,
        &:focus {
            animation-name: specialGrow;
            animation-duration: .25s;
            background-color: $secondary_130;
            color: $text_color_light;
            cursor: pointer;
        }
    }

    .nav-link:hover,
    .nav-link:focus {
        @include link-text-color;
    }

    .language-picker-button {
        background: none;
        border: none;

        &:hover,
        &:focus-visible {
            @include link-text-color;
        }
    }

    .company-page-back-button {
        padding: 0;

        .company-page-back-icon {
            font-size: 2.2rem;
            margin-left: -.6rem;
            color: black;

            &:hover,
            &:focus {
                color: $text_color_primary;
            }
        }
    }

    .burger-menu {
        display: none;
    }

    .burger-menu .dropdown-toggle::after {
        border: none;
    }

    @include media-breakpoint-down(lg) {
        .burger-menu {
            display: inline-block;
        }
    }

}
