@import "../../abstracts/_variables.scss";
@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_mixins.scss";

.bs-3 .calendar-modes button,
.calendar-container .fc-toolbar button {
    margin: 0;
    background-color: white;
    color: $text_color_dark;
    border-radius: 5rem;
    height: unset;
    line-height: 129%;

    &.active,
    &:active,
    &.selected {
        background-color: $neutral_20;
        color: $text_color_dark;
        box-shadow: none;
    }

    &:hover {
        background-color: $neutral_40;
        color: $text_color_dark;
    }

    &:focus {
        box-shadow: $focus_box_shadow_size rgba(white, .5);
    }
}

.calendar-container .fc-toolbar button {
    padding: $button_padding_y_small $button_padding_x_small;
}

.calendar-container.fc .fc-toolbar .btn-round {
    padding: .5rem .75rem;
}

.calendar-container .fc-toolbar {
    @include media-breakpoint-up(xl) {
        justify-content: space-between;
    }

    @include media-breakpoint-up(xxl) {
        flex-wrap: nowrap;

        .fc-right {
            order: 3;
        }

        .fc-center {
            flex: 1 1 100%;
        }
    }
}

.w-100 .calendar-container .fc-toolbar {
    @include media-breakpoint-up(lg) {
        justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
        flex-wrap: nowrap;

        .fc-right {
            order: 3;
        }

        .fc-center {
            flex: 1 1 100%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .fc-toolbar .fc-right .btn-group button {
        padding: .5rem .7rem;

        &:first-child {
            padding-left: 1.5rem;
        }

        &:last-child {
            padding-right: 1.5rem;
        }

    }
}

#datepicker-button::after {
    content: none;
}

.chevron {
    display: inline-block;
    vertical-align: middle;
    @include chevron-pseudo(1em, $text_color_dark, 1);
}
