@import "_palette.scss";

$boka_primary_color: $primary_115;
$boka_light_color: white;
$boka_dark_color: black;

$white_label_primary_color: var(--button-color, $boka_primary_color);

$text_color_light: $boka_light_color;
$text_color_dark: $boka_dark_color;
$text_color_primary: $boka_primary_color;
$text_color_neutral: $neutral_110;
$text_color_neutral_dark: $neutral_150;

$color_disabled: $neutral_80;
$disabled_opacity: .4;

$background_color_light: white;

$background_primary: rgba($primary_30, 1);
$background_primary_light: rgba($primary_10, 1);
$background_primary_dark: rgba($primary_120, 1);

$background_secondary: rgba($secondary_30, 1);
$background_secondary_light: rgba($secondary_10, 1);

$background_neutral: rgba($neutral_30, 1);
$background_neutral_light: rgba($neutral_10, 1);

$secondary_focus_color: rgba($secondary_100, .5);

$button_padding_x: 2rem;
$button_padding_y: .67rem;
$button_padding_x_small: 1.4rem;
$button_padding_y_small: .5rem;
$button_border_radius: 100rem;

$border_color_neutral_dark: $neutral_130;
$input_border_color: rgba(black, .3);


$container_border_radius_small: .3rem;
$container_border_radius_medium: .7rem;
$container_border_radius_large: 1.3rem;
$container_border_radius_boka_corner: 1.6rem;

$font_family: "Montserrat", sans-serif;

$focus_box_shadow_size: 0 0 0 0.25rem;

$gap_default: .6rem 1.2rem;
$form_gap: 1rem 1.5rem;

$shadow_small: 0 .15rem .25rem rgba(black, .2);
$shadow_medium: 0 .4rem .6rem rgba(black, .2);

$navbar_bg_color: rgba(#000000, .8);
$navbar_text_hover_color: rgba($primary_115, 1);
$navbar_height_rem: 3.89rem;
$navbar_logo_height: 2.33rem;

$container_max_widths: (sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1202px);

$animation_transition: 0.15s ease-in-out;
