@import "../abstracts/_palette.scss";

body {
    .color-picker-input-group {
        height: 34px;
    }

    .sp-replacer {
        height: inherit;
        border: 1px solid #ccc;
        padding: .5rem;
        padding-right: 1.5rem;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: 2rem center;
        background-color: #eee;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    .sp-preview {
        border: 1px solid $neutral_120;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background-image: url(data:image/gif;base64,R0lGODlhFAAUAPcAAAAAAJmZmZ2dnZ6enqKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq/Hx8fLy8vT09PX19ff39/j4+Pn5+fr6+vv7+wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAUABQAAAihAP9FoPCvoMGDBy08+EdhQAIJCCMybCDAAYUEARBAlFiQQoMABQhKUJBxY0SPICEYHBnggEmDKAuoPMjS5cGYMxHW3IiT478JJA8M/CjTZ0GgLRekNGpwAsYABHIypcAgQMsITDtWJYBR6NSqMico9cqR6tKfY7GeBCuVwlipDNmefAtTrkSzB1RaIAoXodsABiZAEFB06gIBWC1mLVgBa0AAOw==);
        background-position: -1px;
        background-size: 1rem;

        .sp-preview-inner {
            border-radius: 50%;
            top: -2px;
            bottom: -2px;
            left: -2px;
            right: -2px;
        }
    }

    .sp-dd {
        display: none;
    }

    .sp-container,
    .sp-container * {
        box-sizing: border-box;
    }

    .sp-container {
        margin-bottom: 0;
        padding: 0;
        border-radius: 4px !important;
        background-color: white;
        border: solid 1px #cccccc;
    }

    .sp-palette {
        max-width: 15rem;
    }

    .sp-palette-row {
        display: flex;
        width: max-content;
        gap: .48rem;
        padding-block: .06rem;

        &::before,
        &::after {
            content: none;
        }
    }

    .sp-palette .sp-thumb-el {
        border-radius: 50%;
        width: 1.6rem;
        height: 1.6rem;
        border: 0;
        float: unset;
        display: inline-block;

        &[title="white"] {
            border: 1px solid #ccc;
        }
    }

    .sp-palette .sp-thumb-inner {
        border-radius: 50%;
    }

    .sp-palette-row-selection {
        display: none;
    }

    .empty-color-option {
        cursor: pointer;
        font-size: .9rem;
        font-family: "Montserrat", sans-serif;

        .sp-clear-display {
            display: inline-block;
            height: 1.5rem;
            width: 1.5rem;
            background-size: 1.35rem;
            border-radius: 50%;
            border: 2px solid $neutral_90;
            margin: 0 8px 5px 1px;
            vertical-align: top;

            &:hover {
                border: 2px solid $neutral_90;
            }
        }
    }

    @media (min-width: 768px) {
        .sp-palette {
            max-width: 13.75rem;
        }

        .sp-palette-row {
            gap: .28rem;
        }

        .sp-palette .sp-thumb-el {
            width: 1.3rem;
            height: 1.3rem;
        }

        .empty-color-option .sp-clear-display {
            height: 1.3rem;
            width: 1.3rem;
            background-size: 1.1rem;
        }
    }
    //colorpicker for booking in calendar
    #contextmenu-event-changecolor .sp-replacer {
        display: none;
    }

    #contextmenu-event-changecolor .sp-container {
        top: 1.7rem !important;
        left: 1.2rem !important;
    }
}
