@import "../../abstracts/_palette.scss";
@import "../../abstracts/_breakpoints.scss";

body {
    .connect-info-box i {
        font-size: 1.9rem;
        line-height: 1;

        &.bi-check-circle-fill {
            color: $success;
        }

        &.bi-exclamation-circle-fill {
            color: $danger;
        }
        
    }

    @media (min-width: 768px) {
        .connect-info-box .col-md-auto {
            max-width: calc(100% - 16rem);

            div * {
                white-space: nowrap;
            }
        }
    }

    @media (max-width: 550px) {
        .connect-info-box .col-md-auto div {
            justify-content: space-between;
        }
    }

    #fortnox-settings .form-grid {
        @include media-breakpoint-up(md) {
            grid-template-columns: 55% 1fr;
        }
    }
}
