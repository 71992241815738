body {
    .category-grid {
        display: grid;
        gap: clamp(2rem, 1rem + 5vw, 3rem) clamp(.8rem,.2rem + 3vw, 1.5rem);
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 1.5rem;
    }

    @media (min-width: 768px) {
        .category-grid {
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 2rem;
        }
    }

    @media (min-width: 1101px) {
        .category-grid {
            gap: 2.5rem 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
    //focus categories
    .focus-category-grid {
        display: grid;
        gap: 1rem 2rem;
        padding: 1rem 1rem 0;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 500px) {
        .focus-category-grid {
            grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
        }
    }
    //recently visited
    .recently-visited-grid {
        display: grid;
        gap: clamp(.8rem,.2rem + 3vw, 1.5rem);
        grid-auto-flow: column;
        grid-auto-columns: 43%;
        padding-bottom: clamp(1.7rem,.5rem + 6vw, 2.5rem);
        margin-bottom: clamp(1rem,.2rem + 4vw, 1.7rem);
    }

    @media screen and (min-width: 768px) {
        .recently-visited-grid {
            grid-auto-columns: 28%;
        }
    }

    @media screen and (min-width: 992px) {
        .recently-visited-grid {
            grid-template-columns: repeat(4, 1fr);
            margin-bottom: .5rem;
        }
    }
}
