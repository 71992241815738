@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_variables.scss";
@import "../../abstracts/_mixins.scss";

main:has(>.company-page) ~ footer {
    @include media-breakpoint-down(md) {
        margin-bottom: 4rem;
    }
}


.company-page.d-grid {
    gap: 1rem;
    grid-template-rows: max-content 1fr;
}

.company-page.no-company-info-grid {
    grid-template-columns: minmax(min(40ch, 100%), 65ch);
    place-content: center;
}

@include media-breakpoint-only(sm) {

    .company-page.d-grid {
        gap: 1.5rem;
    }

}

@include media-breakpoint-up(md) {

    .company-page.d-grid {
        gap: 0 clamp(1rem, 4%, 3rem);
    }

    .company-page.company-page-layout-grid {
        //visual studio has a hard time handling naming of grid-columns and rows, but everything in here works.
        grid-template-columns: [main] 1fr [info] clamp(25ch, 30%, 35ch);

        .basic-info-container {
            grid-column: info;
            grid-row: span 2;
            height: max-content;
        }
    }

}

.company-page {

    .header-image {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        aspect-ratio: 2 / 1;
    }

    .temporarily-closed {
        inset: 0;
        background-color: rgba(0, 0, 0, .2);
        backdrop-filter: blur(.3rem);
        aspect-ratio: 2 / 1;
        z-index: 3;

        > div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-only(sm) {
                max-width: 60%;
            }

            @include media-breakpoint-up(lg) {
                max-width: 60%;
            }
        }
    }

    .basic-info-container {
        .bi::before {
            font-size: 1.4em;
        }

        .social-media-icons a {
            line-height: normal;

            .bi::before {
                font-size: 1.5rem;
                color: $neutral_130;
            }
        }
    }

    @include media-breakpoint-only(sm) {

        .basic-info-container {
            grid-template-columns: 1fr 1fr;

            h1,
            .btn-primary,
            .buttons-gap,
            .company-description {
                grid-column: span 2;
            }
        }
    }

    @include media-breakpoint-up(md) {

        .basic-info-container {
            position: sticky;
            max-height: calc(100vh - 1.5rem);
            top: 1.5rem;
        }
    }

    .tabs {
        $tab-border-bottom-width: .3rem;

        background-color: $boka_light_color;
        position: fixed;
        inset: auto .75rem 0 .75rem;
        height: auto;
        z-index: 10;
        box-shadow: 0 -0.15rem 0.4rem 0 rgba(0, 0, 0, 0.16);
        grid-auto-columns: 1fr;
        padding-block: .75rem;

        //to make sure nothing bleeds on the sides of the nav
        margin-inline: -1rem;
        padding-inline: 1rem;

        button {
            appearance: none;
            background-color: transparent;
            border: 0;
            padding-bottom: calc(.3rem + $tab-border-bottom-width);
        }

        .active {
            border-bottom: $tab-border-bottom-width solid $white_label_primary_color;
        }

        .tab-border {
            inset: .75rem 1rem;
            border-bottom: $tab-border-bottom-width solid $neutral_20;
            z-index: -1;
        }

        > * {
            white-space: nowrap;
            padding-inline: 0;
        }

        @include media-breakpoint-up(md) {
            position: sticky;
            inset: 0 auto auto;
            box-shadow: none;

            button {
                padding-bottom: calc(.5rem + $tab-border-bottom-width);
            }

            .tab-border {
                bottom: 1.5rem;
            }
        }
    }
    
}


@include media-breakpoint-up(md) {
    .below-navbar .company-page {
        .tabs {
            inset: $navbar_height_rem auto auto;
        }

        .basic-info-container {
            max-height: calc(100vh - $navbar_height_rem - 1.5rem);
            top: calc($navbar_height_rem + 1.5rem);
        }
    }
}

.bi-twitter-x::before {
    content: "\f8db";
}
