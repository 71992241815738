 @import "../../abstracts/_variables.scss";
 @import "../../abstracts/_breakpoints.scss";

.bs-3 .context-menu.dropdown-menu {
    padding: .3rem .5rem;

    i {
        margin-right: .5rem;
    }

    a {
        color: inherit;
    }

    .divider {
        border-bottom: 1px solid $neutral_40;
        margin-block: .3rem;

        + .divider {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {

        left: 50% !important;
        transform: translateX(-50%);

        .sp-thumb-el {
            width: 1.2rem;
            height: 1.2rem;
        }

        .sp-clear-display {
            width: 1.1rem;
            height: 1.1rem;
        }
    }
}
