@import "../../abstracts/_breakpoints.scss";

.front-page {
    .marketplace-carousel {

        @include media-breakpoint-up(md) {
            flex: 0 0 60%;
        }

        .carousel-item {
            padding-bottom: 2rem;
        }

        .category-picture {
            aspect-ratio: 1.5;
        }

        .category-text-container {
            transform: translateY(55%);

            .category-text-background {
                padding: .2rem .5rem;
            }

            @include media-breakpoint-up(md) {
                transform: translateY(45%);

                .category-text-background {
                    padding: .35rem .75rem;

                    span {
                        display: block;
                        line-height: 1.4em;
                    }
                }
            }

            @include media-breakpoint-only(md) {
                .category-text-background span {
                    font-size: .89rem;
                }
            }
        }
    }


    .grid-of-3 {
        grid-template-columns: repeat(3, 1fr);

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
