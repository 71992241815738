@import "../../abstracts/_variables.scss";
@import "../../abstracts/_breakpoints.scss";

.resource-buttons {
    @include media-breakpoint-down(sm) {
        min-width: 50%;
    }
}

#visible-resources-container,
#schedule-resources-container {
    width: max-content;

    @include media-breakpoint-down(sm) {
        flex-grow: 1;

        .multiselect-selected-text {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100vw - 7rem);
            vertical-align: bottom;
            display: inline-block;
        }
    }

    .multiselect-container {
        min-width: min-content !important;

        a {
            padding: 0;
        }

        label {
            margin: 0;
            padding: .5rem 1rem;
        }

        input {
            position: unset;
            margin-inline: 0 .5rem;
        }
    }
}

.bs-3 .dropdown-toggle.rounded-circle {
    padding: 0.6rem 0.65rem;
    font-size: 1.22rem;
    line-height: 1.22rem;
    height: min-content;

    &::after {
        content: none;
    }
}

.bs-3 ul.button-list-expand {

    @include media-breakpoint-up(md) {
        position: static;
        border: 0;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
        float: unset;
    }

    .dropdown-item {
        line-height: 1.5;
        padding: .2rem 1rem;
        transition: all $animation_transition;

        &:hover {
            background-color: $neutral_10;
        }

        &.calendar-color {
            background-color: var(--calendar-color, $boka_primary_color);
            color: var(--calendar-text-color, $text_color_light);

            &:hover {
                background-color: var(--calendar-color, $boka_primary_color);
                color: var(--calendar-text-color, $text_color_light);
                opacity: .8;
            }

            @include media-breakpoint-down(md) {
                color: $text_color_dark;
                background-color: transparent;

                &:hover {
                    background-color: $neutral_10;
                    color: $text_color_dark;
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding: $button_padding_y $button_padding_x;
            border-radius: $button_border_radius;
            font-weight: 700;
            background-color: $neutral_20;


            &:hover {
                background-color: $neutral_40;
            }
        }
    }
}

.calendar-modes.btn-group {
    border: 1px solid $neutral_40;
    border-radius: 5rem;
}

.bs-3 .calendar-modes button {
    padding: $button_padding_y $button_padding_x;

    @include media-breakpoint-down(sm) {
        padding: $button_padding_y 1rem;

        &:first-child {
            padding-left: $button_padding_x_small !important;
        }

        &:last-child {
            padding-right: $button_padding_x_small !important;
        }
    }
}
