@import "../abstracts/_variables.scss";
@import "../abstracts/_breakpoints.scss";

body {
    //font-size
    .fs- {
        &14 {
            font-size: .78rem !important;
        }

        &16 {
            font-size: .89rem !important;
        }

        &18 {
            font-size: 1rem !important;
        }

        &20 {
            font-size: 1.11rem !important;
        }

        &24 {
            font-size: 1.33rem !important;
        }

        &32 {
            font-size: 1.78rem !important;
        }

        &md- {
            @include media-breakpoint-up(md) {
                &16 {
                    font-size: .89rem !important;
                }

                &18 {
                    font-size: 1rem !important;
                }

                &20 {
                    font-size: 1.11rem !important;
                }

                &24 {
                    font-size: 1.33rem !important;
                }

                &32 {
                    font-size: 1.78rem !important;
                }
            }
        }
    }
    //font-weight
    .fw- {
        &semibold {
            font-weight: 600 !important;
        }

        &medium {
            font-weight: 500 !important;
        }
    }
    //width
    .min-content {
        width: min-content !important;
    }

    .max-content {
        width: max-content !important;
    }

    .fit-content {
        width: fit-content !important;
    }
    //border radius
    .boka-corners-1 {
        border-top-right-radius: $container_border_radius_boka_corner !important;
        border-bottom-left-radius: $container_border_radius_boka_corner !important;
        border-top-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .boka-corners-2 {
        border-top-left-radius: $container_border_radius_boka_corner !important;
        border-bottom-right-radius: $container_border_radius_boka_corner !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    //aspect-ratio
    .aspect-ratio-1 {
        aspect-ratio: 1;
    }
    //cursor
    .clickable {
        cursor: pointer;
    }


    .disabled,
    .unclickable {
        pointer-events: none !important;
    }

    .white-space-pre-line {
        white-space: pre-line;
    }

    .overflow-ellipsis { 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .exempt-of-vat::after {
        content: "*";
        color: $white_label_primary_color;
        font-weight: 600;
        margin-left: .2em;
    }

    .hidden-print {
        @media print {
            display: none !important;
        }
    }
}
