@import "../abstracts/_palette.scss";

body {
    .focus-category-item {
        width: max-content;
        max-width: 100%;
        justify-self: center;

        .focus-category-text {
            font-size: min(.35rem + 2vw, 1rem);
            margin-top: 1.5rem;
            display: flex;
            justify-content: center;
        }
    }

    .focus-category-image-container {
        overflow: hidden;
        display: block;
        width: 100%;
        max-width: 13ch;
        min-height: 100%;
        aspect-ratio: calc(1 / 1);
        border-radius: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .focus-category-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-transition: all .15s linear;
        transition: all .15s linear;
    }

    .focus-category-item:hover {
        .focus-category-image {
            -ms-transform: scale(1.125);
            -webkit-transform: scale(1.125);
            transform: scale(1.125);
        }

        .focus-category-text {
            color: $neutral_150;
        }
    }
}
