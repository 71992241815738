@import "../abstracts/_palette.scss";

body {
    .category-text-container {
        margin-left: min(0.2em + 2vw, 1.3rem);
    }

    .category-text-background {
        width: max-content;
        padding: 0 .75rem;
        margin-bottom: 0;

        span {
            font-size: min(.35rem + 2vw, 1.11rem);
            line-height: 2.3em;
        }
    }

    .category-picture {
        width: 100%;
        height: auto;
        aspect-ratio: calc(3 / 2);
        object-fit: cover;
    }

    .category-text-container {
        bottom: 0;
        transform: translateY(50%);
    }

    a:focus .category-text-container {
        outline: 2px solid $boka_primary_color;
    }

    @media (max-width: 767px) {

        .category-picture {
            width: 100%;
            height: auto;
            aspect-ratio: calc(2 / 1);
            object-fit: cover;
        }
    }

    @media (max-width: 1100px) {
        .category-text-background {
            padding: .35rem .75rem;

            span {
                display: block;
                line-height: 1.4em;
            }
        }
    }

    @keyframes on-hover {
        0% {
            outline-color: rgba($primary_50, 0);
            outline-width: 0;
            outline-style: solid;
            //border: 0 solid rgba($primary_50, 0);
        }

        100% {
            outline-color: $primary_50;
            outline-width: 10px;
            outline-style: solid;
            //border: .25rem solid rgba($primary_50, 1);
        }
    }

    .category-picture-hover-effect {
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 .125rem .25rem rgba(black, .3);
    }

    .category-picture-hover-effect:hover {
        box-shadow: 0 0.15rem 0.4rem 0.1rem rgb(black, .3);
    }

    .category-parent .category-picture {
        -webkit-transition: all .15s linear;
        transition: all .15s linear;
    }

    .category-parent:hover .category-picture {
        -ms-transform: scale(1.125);
        -webkit-transform: scale(1.125);
        transform: scale(1.125);
    }
}
