@import "../abstracts/_palette.scss";
@import "../abstracts/_mixins.scss";
@import "../abstracts/_breakpoints.scss";

body {
    //firefox
    .custom-scroller {
        @include firefox-scroller-colors;
        scrollbar-width: thin;
        
        //Other browsers
        //Width
        &::-webkit-scrollbar {
            width: .6rem;
            height: .6rem;
        }
        //Track
        &::-webkit-scrollbar-track {
            background: $neutral_10;
            border-radius: .3rem;
        }
        //Handle
        &::-webkit-scrollbar-thumb {
            @include primary-button-color;
            border-radius: .3rem;
        }
        //Handle on hover
        &::-webkit-scrollbar-thumb:hover {
            @include primary-button-hover-color;
        }
    }

    .fake-scrollbar-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        height: 100rem; //needs a absolute value for fake scroller to work

        .fakeScroll__track {
            right: unset;
            left: calc(100% + .3rem);
            width: .3rem;
            border-radius: 1rem;

            @include media-breakpoint-up(md) {
                width: .5rem;
            }
        }

        .fakeScroll__bar {
            @include primary-button-color;
            border-radius: 1rem;
            cursor: grab;

            &:hover {
                @include primary-button-hover-color;
            }

            
        }
    }
}
