//Primary
$primary_150: #065255;
$primary_140: #076265;
$primary_130: #087376;  //dark and hover
$primary_120: #098387;
$primary_115: #0A8B90;  //use this for buttons and links
$primary_115_rgb: 10, 139, 144;  //needed for transparency that uses rgba
$primary_110: #0A9398;  
$primary_100: #0CA4A9;  //default
$primary_100_rgb: 12, 164, 169;  //needed for focus-effect that uses rgba
$primary_90: #23ACB1;
$primary_80: #3CB6BA;
$primary_70: #54BFC2;
$primary_60: #6DC8CB;
$primary_50: #85D1D4;
$primary_40: #9DDADC;
$primary_30: #B5E3E5;
$primary_20: #CEEDEE;   //light
$primary_10: #E6F6F6;
$primary_5: #F2FAFB;

//Secondary
$secondary_150: #62462D;
$secondary_140: #775536;
$secondary_130: #8A633F;    //dark and hover
$secondary_120: #9F7148;
$secondary_115: #A8784C;    //use this for buttons and links
$secondary_110: #B27F51;
$secondary_100: #C68D5A;    //default
$secondary_100_rgb: 198, 141, 90;   //needed for focus-effect that uses rgba
$secondary_90: #CB986A;
$secondary_80: #D1A37B;
$secondary_70: #D7AF8B;
$secondary_60: #DDBA9C;
$secondary_60: #DDBA9C;
$secondary_50: #E2C6AC;
$secondary_40: #E8D1BD;
$secondary_30: #EEDCCD;
$secondary_20: #F4E8DE;     //light
$secondary_10: #F9F3EE;
$secondary_5: #FCF9F6;

//Neutral (grayscale)
$neutral_150: #474747;
$neutral_140: #565656;
$neutral_130: #646464;
$neutral_120: #737373;
$neutral_110: #808080;
$neutral_100: #8F8F8F;
$neutral_90: #9A9A9A;
$neutral_80: #A5A5A5;
$neutral_70: #B0B0B0;
$neutral_60: #BCBCBC;
$neutral_50: #C7C7C7;
$neutral_40: #D2D2D2;
$neutral_30: #DDDDDD;
$neutral_20: #E9E9E9;
$neutral_10: #F4F4F4;
$neutral_5: #F9F9F9;

//Danger
$danger_150: #6E1A22;
$danger_120: #B02A37;
$danger: #DC3545;
$danger_50: #ED99A1;
$danger_20: #F8D7DA;

//warning
$warning_150: #804C00;
$warning_120: #CC7A00;
$warning: #FF9900;
$warning_50: #FFCB7F;
$warning_20: #FFEBCC;

//info
$info_150: #0E5161;
$info_120: #16829C;
$info: #1CA2C3;
$info_50: #8DD0E0;
$info_20: #D2ECF3;

//success
$success_150: #264D00;
$success_120: #3D7B00;
$success: #4C9A00;
$success_50: #A5CC7F;
$success_20: #DBEBCC;


//Coral (red)
$coral_150: #7D4340;
$coral_140: #98524D;
$coral_130: #B05F5A;
$coral_120: #CA6D67;
$coral_110: #E27A73;
$coral_100: #FC8880;
$coral_90: #FC938C;
$coral_80: #FC9F99;
$coral_70: #FDABA6;
$coral_60: #FDB7B3;
$coral_50: #FDC3BF;
$coral_40: #FECFCC;
$coral_30: #FEDBD9;
$coral_20: #FFE7E6;
$coral_10: #FFF3F2;

//Pink
$pink_150: #873D45;
$pink_140: #9E4F58;
$pink_130: #B0646E;
$pink_120: #C47A7E;
$pink_110: #DB999F;
$pink_100: #EDB2B8;
$pink_90: #EEB9BF;
$pink_80: #F0C1C6;
$pink_70: #F2C9CD;
$pink_60: #F4D1D4;
$pink_50: #F6D8DB;
$pink_40: #F8E0E3;
$pink_30: #FAE8EA;
$pink_20: #FCF0F1;
$pink_10: #FDF7F8;

//Orange
$orange_150: #803C0D;
$orange_140: #994912;
$orange_130: #B35C1E;
$orange_120: #CC712D;
$orange_110: #E07E38;
$orange_100: #EF8C45;
$orange_90: #F09757;
$orange_80: #F2A36A;
$orange_70: #F4AE7C;
$orange_60: #F5BA8F;
$orange_50: #F7C5A1;
$orange_40: #F9D1B4;
$orange_30: #FADCC7;
$orange_20: #FCE8DA;
$orange_10: #FEF3EC;

//Yellow
$yellow_150: #995B00;
$yellow_140: #B36B00;
$yellow_130: #D68B00;
$yellow_120: #DE9B00;
$yellow_110: #E8AA1B;
$yellow_100: #FFC94F;
$yellow_90: #FFCE60;
$yellow_80: #FFD372;
$yellow_70: #FFD983;
$yellow_60: #FFDE95;
$yellow_50: #FFE4A6;
$yellow_40: #FFE9B8;
$yellow_30: #FFEFCA;
$yellow_20: #FFF4DC;
$yellow_10: #FFFAED;

//Blue
$blue_150: #3B515D;
$blue_140: #476370;
$blue_130: #537383;
$blue_120: #5E8396;
$blue_110: #6A93A8;
$blue_100: #76A4BB;
$blue_90: #83ACC1;
$blue_80: #91B6C8;
$blue_70: #9EBFCF;
$blue_60: #ACC8D6;
$blue_50: #BAD1DD;
$blue_40: #C8DAE4;
$blue_30: #D5E3EA;
$blue_30: #E3EDF1;
$blue_10: #F1F6F8;

//Purple
$purple_150: #663155;
$purple_140: #7C3C67;
$purple_130: #904578;
$purple_120: #A54F8A;
$purple_110: #B9599B;
$purple_100: #CE63AC;
$purple_90: #D272B4;
$purple_80: #D782BC;
$purple_70: #DC91C4;
$purple_60: #E1A1CD;
$purple_50: #E6B0D5;
$purple_40: #EBC0DE;
$purple_30: #F0D0E6;
$purple_20: #F5E0EE;
$purple_10: #FAEFF7;

//Brown
$brown_150: #5C4F47;
$brown_140: #706057;
$brown_130: #827065;
$brown_120: #958073;
$brown_110: #A79081;
$brown_100: #BAA090;
$brown_90: #C0A99A;
$brown_80: #C7B3A6;
$brown_70: #CEBCB1;
$brown_60: #D5C6BC;
$brown_50: #DCCFC7;
$brown_40: #E3D9D2;
$brown_30: #EAE2DD;
$brown_20: #F1ECE9;
$brown_10: #F8F5F4;

//Green
$green_150: #3B5E42;
$green_140: #487250;
$green_130: #53855D;
$green_120: #5F986B;
$green_110: #6BAB77;
$green_100: #77BE85;
$green_90: #84C491;
$green_80: #92CB9D;
$green_70: #9FD1A9;
$green_60: #ADD8B6;
$green_50: #BADEC2;
$green_40: #C8E5CE;
$green_30: #D6EBDA;
$green_20: #E4F2E7;
$green_10: #F1F9F3;

