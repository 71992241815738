@import "../abstracts/_variables.scss";

body {
    ol li::marker {
        color: $text_color_primary;
        font-weight: 600;
    }

    ol li.header2::marker {
        font-size: 1.78rem;
        line-height: 131%;
        font-weight: 700;
    }

    ul li::marker {
        color: inherit;
    }
}
