@import "../abstracts/_palette.scss";
@import "../abstracts/_variables.scss";

body {

    .card-button {
        border: none;
        color: $text_color_dark;
        background-color: white;
        border-radius: 0;
        padding: 0.5rem 1rem 0;
        cursor: pointer;
        box-shadow: $shadow_small;

        h2 {
            color: $boka_primary_color;
        }

        &:hover {
            box-shadow: $shadow_medium;

            .card-button-circle:not(.checkbox-animation) {
                animation-name: shrink;
                animation-duration: .25s;
                animation-fill-mode: forwards;
            }
        }
    }

    .card-button-circle-text {
        margin-bottom: 1rem;
        padding-right: 6px;
        display: none;
    }

    .card-button-circle {
        background-color: $neutral_10;
        width: 2rem;
        height: 2rem;
        border-radius: 100rem;
        border: 0.16rem solid $neutral_80;
        margin-bottom: 1rem;

        i {
            color: white;
            font-size: 1.4rem;
            margin-top: 4px;
            display: none;
        }
    }

    input[type="radio"]:checked + .card-button {
        outline: .4rem solid $boka_primary_color;
        cursor: context-menu;

        h2 {
            color: $text_color_dark;
        }

        .card-button-circle-text {
            display: inline-block;
        }

        .card-button-circle {
            background-color: $boka_primary_color;
            border: none;
            width: 2.16rem;
            height: 2.16rem;

            i {
                display: inline-block;
            }
        }
    }

    .checkbox-animation {
        animation-name: grow;
        animation-duration: .25s;
        animation-fill-mode: forwards;

        i {
            animation-name: show;
            animation-duration: .4s;
            animation-fill-mode: forwards;
        }
    }

    @keyframes shrink {
        0% {
            width: 2rem;
            height: 2rem;
        }

        100% {
            width: 1.7rem;
            height: 1.7rem;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
            font-size: 0;
        }

        100% {
            opacity: 1;
            font-size: 1.4rem;
        }
    }

    @keyframes grow {
        0% {
            width: 1.7rem;
            height: 1.7rem;
        }

        25% {
            width: 1.4rem;
            height: 1.4rem;
        }

        100% {
            width: 2.16rem;
            height: 2.16rem;
        }
    }
}
