@import "../abstracts/_mixins.scss";
@import "../abstracts/_palette.scss";

body {

    h1,
    .heading-1 {
        font-size: clamp(1.56rem, 1.3rem + 3vw, 2.56rem);
        line-height: 122%;
        font-weight: 900;
        @include text-color;
    }

    h2,
    .heading-2 {
        font-size: clamp(1.33rem, 1rem + 2vw, 1.78rem);
        line-height: 131%;
        font-weight: 700;
        @include text-color;
    }

    h3,
    .heading-3 {
        font-size: clamp(1.11rem, .75rem + 2vw, 1.56rem);
        line-height: 136%;
        font-weight: 700;
        @include text-color;
    }

    h4,
    .heading-4 {
        font-size: clamp(1rem, .5rem + 2vw, 1.33rem);
        line-height: 142%;
        font-weight: 700;
        @include text-color;
    }

    a {
        font-weight: 600;
        @include link-text-color;

        &:hover {
            @include link-text-color;
        }
    }

    label {
        font-size: 1rem;
        line-height: 144%;
    }

    .body-text {
        font-size: 1rem;
        line-height: 144%;
        font-weight: 400;
        @include text-color;
    }

    .big-body-text {
        font-size: clamp(1.11rem, .75rem + 2vw, 1.56rem);
        line-height: 136%;
        font-weight: 400;
        @include text-color;
    }

    .linked-body-text {
        font-size: 1rem;
        line-height: 144%;
        font-weight: 700;
        @include link-text-color;
    }

    .linked-text-small {
        font-size: 0.89rem;
        line-height: 138%;
        font-weight: 700;
        @include link-text-color;
    }

    .button-text {
        font-size: 0.78rem;
        line-height: 129%;
        text-transform: uppercase;
        font-weight: 700;
        //@include text-color;
    }

    .icon-font {
        font-family: bootstrap-icons;
    }

    .subtitle-text {
        font-size: 1.11rem;
        line-height: 150%;
        font-weight: 700;
        @include text-color;
    }

    .subtitle-uppercase-text {
        font-size: 1.11rem;
        line-height: 122%;
        text-transform: uppercase;
        font-weight: 700;
        @include text-color;
    }

    .caption-text {
        font-size: 0.78rem;
        line-height: 129%;
        font-style: italic;
        font-weight: 600;
        @include text-color;
    }

    .overline-text {
        font-size: 0.78rem;
        line-height: 129%;
        text-transform: uppercase;
        font-weight: 600;
        @include text-color;
    }

    .facts-heading-text {
        font-size: 0.89rem;
        line-height: 138%;
        font-weight: 600;
        @include text-color;
    }

    .facts-text {
        font-size: 0.89rem;
        line-height: 138%;
        font-weight: 500;
        @include text-color;
    }
}
