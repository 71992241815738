@import "../abstracts/_mixins.scss";
@import "../abstracts/_variables.scss";

body {

    .custom-details {

        > summary {
            list-style: none;
            @include link-text-color;
            font-weight: 600;
            margin-bottom: .75em;

            &::before {
                content: "\F231";
                font-family: bootstrap-icons;
                display: inline-block;
                vertical-align: bottom;
                margin-right: 1em;
                transition: transform $animation_transition;
            }
        }

        &[open] > summary::before {
            transform: rotate(90deg);
        }
    }
}
