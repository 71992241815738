@import "../../abstracts/_variables.scss";
@import "../../abstracts/_breakpoints.scss";
@import "../../abstracts/_mixins.scss";

#external-events-container {
    display: none;
    min-width: 12rem;
    flex: 0 1 13%;
    height: max-content;
    grid-template-columns: 100%;

    @include media-breakpoint-up(md) {
        display: grid;
    }

    h2.title {
        font-size: .89rem;
    }

    .title,
    .menu-expand {
        padding: .6rem 1rem;
        font-size: .78rem;
        font-weight: 600;
        margin: 0 0 .5rem;
        display: flex;
        align-content: center;
        justify-content: space-between;
        border-radius: .3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > span {
            max-width: calc(100% - 2rem);
        }
    }

    .menu-container {
        margin-bottom: 1rem;

        > div {
            max-height: 0;
            transition: max-height $animation_transition;
        }
    }

    .menu-expand {
        position: relative;
        color: $text-muted;
        cursor: pointer;

        &::after {
            @include chevron-pseudo(1.2em, $text-muted, 1.2);
            transition: transform $animation_transition;
        }
    }

    .ui-draggable {
        cursor: pointer;
    }

    .menu-container.open {
        > div {
            max-height: 50rem;
        }

        .menu-expand::after {
            transform: scaleY(-1);
        }
    }

    .fc-event {
        padding: .3rem 1rem;
        background-color: $info_50;
        color: $text_color_dark;
        border: 0;
        border-radius: .3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .fa::before {
        vertical-align: middle;
    }

    #adminEventsHeader {
        background-color: $info_20;
    }
}
