@import "../abstracts/_variables.scss";

body {

    .offcanvas:focus {
        outline: none;
    }

    .offcanvas-end {
        width: 430px;
        border-left: 0;
    }

    .offcanvas-backdrop {
        margin-top: $navbar_height_rem;
        backdrop-filter: blur(50px); //not supported?
    }

    #loginCanvas { //kanske borde gälla alla offcanvas istället
        --button-color-rgb: 12, 164, 169; //primary_100_rgb
        --button-color: #0A9398;
        --button-color-hover: #087376;
        --link-text-color: #0A9398;
        --link-text-color-hover: #087376;
        font-family: $font_family;
    }

    #loginCanvas.background-secondary {

        .button-group-background {
            background-color: $secondary_70;
        }

        .button-group-button {


            &.active {
                color: $secondary_10;
                background-color: $secondary_115;
                border-color: $secondary_10;

                &:hover {
                    background-color: $secondary_130;
                }
            }

            &:hover {
                background-color: $secondary_90;
            }

            &:focus {
                box-shadow: 0 0 0 0.25rem rgba($secondary_10, 0.25);
            }
        }
    }
}

