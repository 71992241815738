@import "../abstracts/_palette.scss";
@import "../abstracts/_variables.scss";

.footer-body-text {
    font-size: 0.78rem;
    font-weight: 400;
    text-decoration: none;
    color: $text_color_light;

    &:hover {
        color: $neutral_20;
    }
}

.footer-copyright-text {
    font-size: 0.6rem;
    font-weight: 400;
    color: $text_color_light;
}
.footer-container-light {
    background-color: $neutral_140; 
}

.footer-container-dark {
    background-color: $neutral_150;
}
