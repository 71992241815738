@import "../../abstracts/_palette.scss";
@import "../../abstracts/_mixins.scss";
@import "../../abstracts/_variables.scss";

body {

    .supplier-event-card {
        margin-bottom: 1.5rem;
        color: $text_color_dark;

        .card-hover-effect {
            overflow: hidden;
            position: relative;
        }

        .card-image {
            width: 100%;
            height: 100%;
            aspect-ratio: calc(16/9);
            object-fit: cover;
        }

        .card-event-banner {
            position: absolute;
            top: .5rem;
            right: 0;
            background-color: $secondary_40;
            padding: .3rem 2.5rem .3rem .8rem;
        }

        .card-text-container {
            margin-left: .8rem;
            margin-top: -1.75rem;

            .card-address-text {
                padding-left: .75rem;
                font-size: min(.5rem + 1.5vw, .89rem);
                font-weight: 400;
                padding-bottom: .2rem;
            }

            .card-text-mini {
                font-size: min(.4rem + 1.5vw, .78rem);
                padding-left: .75rem;
                font-weight: 400;
            }

            p {
                margin-bottom: 0;
            }
        }

        .card-title-container {
            display: inline-block;
            max-width: 90%;
            background-color: $secondary_20;
            position: relative;
            padding: .2rem .75rem .3rem;
            margin-bottom: .6rem;
            z-index: 2;

            .card-text-mini {
                padding: 0;
            }
        }

        .card-title {
            font-size: min(.5rem + 2vw, 1.11rem);
            font-weight: 600;
            margin-bottom: .1rem;
        }

        .stars-text {
            font-size: min(.4rem + 1.5vw, .78rem);
            font-weight: 400;

            strong {
                padding-left: .2rem;
            }
        }

        .stars-margin {
            margin-right: .2em;
        }
    }
    //hover effect animation
    .supplier-event-card .card-image {
        -webkit-transition: all .15s ease-in-out;
        transition: all .15s ease-in-out;
    }

    .supplier-event-card:hover .card-image {
        -ms-transform: scale(1.125);
        -webkit-transform: scale(1.125);
        transform: scale(1.125);
    }
}

//disable horizontal scroll for infowindow map card
#search-result-map .gm-style-iw-d {
    overflow: hidden auto;
}

//card in google maps
.gm-style-iw.gm-style-iw-c {
    width: 300px;
    min-width: 300px !important;
}

.google-maps-infowindow-wrapper {
    font-family: $font_family;

    .supplier-event-card {
        margin-bottom: .2rem;
    }

    .card-text-container .big-body-text {
        padding-left: 12px;
        font-size: 0.89rem;
        font-weight: 400;
        padding-bottom: 4px;
    }

    .card-text-container .facts-text {
        padding-left: 12px;
        font-weight: 400;
        font-size: 0.68rem;
    }

    .btn {
        padding: 0.5rem 1.4rem;
    }

    .col-lg-4,
    .col-sm-6 {
        width: 276px;
    }
}
