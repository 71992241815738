body {

    .horizontal-scroller {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        
        > * {
            scroll-snap-align: start;
        }
        > *:last-child {
            scroll-snap-align: end;
        }
    }
}
