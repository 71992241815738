@import "../abstracts/_variables.scss";

.boka-loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    perspective: 15rem;
    perspective-origin: bottom left;
    pointer-events: none;
}

.upper-quarter,
.lower-quarter {
    --loader-size: 2rem;
    width: var(--loader-size);
    height: var(--loader-size);
    transform-origin: left;
    border-bottom-right-radius: 100%;
    animation: boka-loader 2.5s ease-in-out infinite;
    backface-visibility: hidden;

}

.upper-quarter {
    background-color: $primary_100;
    animation-delay: .05s;
}

.lower-quarter {
    margin-top: calc(var(--loader-size) / 5);
    background-color: $secondary_100;
    animation-delay: .25s;
}

@keyframes boka-loader {
    15% {
        transform: rotateY(0deg);
    }
    70% {
        transform: rotateY(-180deg);
    }
    95% {
        transform: rotateY(-370);
    }
    100% {
        transform: rotateY(-360deg);
    }
}
